<template>
  <div class="chat-container">
    <div class="chat-sidebar">
      <div class="chat-search">
        <textInput
          placeholder="Введите имя..."
          autocomplete="off"
        >
          <template v-slot:before>
            <button class="search-button">
              <icon class="search-icon" name="search"/>
            </button>
          </template>
        </textInput>
      </div>
      <div class="chat-list">
        <chat-list-item
          v-for="chat in chats"
          :key="chat.id"
          :chat="chat"
          @select="selectChat"
        />
      </div>
    </div>
    <div class="chat">
      <chatArea
        :chatData="selectedChat"
        @readUnanswered="readMessages"
      />
    </div>
  </div>
</template>

<script>
import chatArea from '@/components/chat/chatArea'
import ChatListItem from '@/components/chat/chatListItem';
import textInput from '@/components/textInput';

export default {
  name: 'messagesUnanswered',
  components: {
    textInput,
    ChatListItem,
    chatArea
  },
  data () {
    return {
      chats: []
    }
  },
  computed: {
    // Получение данных для выбранного чата
    selectedChat () {
      const selectedChat = this.chats.find(el => el.selected);
      if (selectedChat) {
        return {
          id: selectedChat.id,
          name: selectedChat.name,
          avatar: selectedChat.avatar,
          channel: selectedChat.channel
        };
      }
      return {}
    }
  },
  methods: {
    readMessages () {
      const findEl = this.chats.find(el => el.id === this.selectedChat.id)
      if (findEl) {
        this.$store.commit(
          'techStore/minusMessages',
          {
            minus: findEl.messages
          }
        )
        findEl.messages = 0
      }
    },
    selectChat (chatId) {
      const oldSelectedI = this.chats.findIndex(el => el.selected)
      if (oldSelectedI !== -1) {
        this.chats[oldSelectedI].selected = false
      }
      const newSelectedI = this.chats.findIndex(el => el.id === chatId)
      if (newSelectedI !== -1) {
        this.chats[newSelectedI].selected = true
      }
    }
  },
  created () {
    fetch(window.host + 'dialogs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.chats = data.data.map((el, index) => {

        const chatDate = new Date(el.datetime);
        const today = new Date();

        // Функция для проверки, является ли дата сегодняшней
        const isToday = chatDate.toDateString() === today.toDateString();

        return {
          name: el.client_name,
          id: el.id,
          time: !el.datetime
            ? 'Новый чат' // Если chatDate отсутствует, показываем "Новый чат"
            : isToday
              ? chatDate.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' }) // Если чат был сегодня, показываем время
              : (chatDate > Date.now() - 7 * 24 * 60 * 60 * 1000) // Если чат был в последние 7 дней, показываем день недели
                  ? chatDate.toLocaleString('ru-RU', { weekday: 'long' }) // Показываем день недели
                  : chatDate.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' }),
          last_message: el.last_message,
          selected: index === 0,
          messages: el.messages,
          channel: el.system,
          avatar: {
            gender: el.gender,
            id: el.id_picture,
          }
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    this.$nextTick(function () {
      setTimeout(() => {
        this.$store.commit('socketStore/subscribe', {
          channel: `private-dialogs.${this.$store.state.techStore.companyId}`,
          eventName: 'App\\Events\\Dialogs',
          callback: socketData => {
            if (socketData.messages) {
              const findEl = this.chats.find(el => el.id === socketData.dialogId)
              findEl.messages = socketData.messages
            }
          }
        })
      }, 500)
    })
  },
  unmounted () {
    this.$store.commit(
      'socketStore/unsubscribe',
      {
        channel: `private-dialogs.${this.$store.state.techStore.companyId}`
      }
    )
  }
}
</script>

<style lang="scss" src="./messagesChat.scss" scoped />
