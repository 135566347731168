import router from '@/router'

export default {
  namespaced: true,
  state() {
    return {
      titleName: '',
      isAuth: false,
      blockLogout: false,
      messagesCount: 0,
      purchasesCount: 0,
      clientsCount: 0,
      companyName: false,
      companyLogo: false,
      companyId: false,
      isSidebarOpened: false,
      isFormChanged: false,
      loader: true,
      formSaveCallback: () => {
      }
    }
  },
  mutations: {
    setLoader(state, props) {
      const {action} = props
      setTimeout(() => {
        state.loader = action
      }, 500)
    },
    setTitleName(state, props) {
      const {name} = props
      state.titleName = name || ''
    },
    setMessagesCount(state, props) {
      const {counter} = props
      state.messagesCount = counter || 0
    },
    setPurchasesCount(state, props) {
      const {counter} = props
      state.purchasesCount = counter || 0
    },
    setClientsCount(state, props) {
      const {counter} = props
      state.clientsCount = counter || 0
    },
    minusMessages(state, props) {
      const {minus} = props
      state.messagesCount = state.messagesCount - minus
    },
    setFormChanged(state, props) {
      const {isChanged, callback} = props
      state.isFormChanged = isChanged
      state.formSaveCallback = callback
    },
    setCompanyData(state, props) {
      const {path, name, id, messagesCount, purchasesCount, clientsCount} = props
      if (path) {
        state.companyLogo = path
      }
      if (name) {
        state.companyName = name
      }
      if (id) {
        state.companyId = id
      }
      if (messagesCount) {
        state.messagesCount = messagesCount
      }
      if (purchasesCount) {
        state.purchasesCount = purchasesCount
      }
      if (clientsCount) {
        state.clientsCount = clientsCount
      }
    },
    toggleSidebar(state) {
      state.isSidebarOpened = !state.isSidebarOpened
    },
    doAuth(state) {
      state.isAuth = true
    },
    async checkLogin(state, props) {
      const {path} = props
      if (localStorage.getItem('access_token')) {
        await router.push(`/${path}`)
      }
    },
    logout() {
      localStorage.removeItem('access_token')
      router.push('/login')
    },
    checkError(state, props) {
      const {err} = props
      if (err.status === 401) {
        if (!state.blockLogout) {
          state.blockLogout = true

          state.isAuth = false
          localStorage.removeItem('access_token')
          router.push('/login')

          setTimeout(() => {
            state.blockLogout = false
          }, 300)
        }
      } else {
        this.commit('animateStore/animateNotification', {
          text: 'Ошибка загрузки данных',
          desc: 'Сервер недоступен. Попробуйте обновить страницу или зайти позже.',
          status: 'error'
        })
      }
    }
  }
}
