<template>
  <button :class="buttonClasses" :disabled="disabled || isLoading" @click="clickOnButton">
    <span>
      <slot>Далее</slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'xButton',
  data() {
    return {
      isLoading: false,
      timeoutId: null
    };
  },
  props: {
    size: {
      validator (value) {
        return ['extra-small', 'small', 'middle', 'big', 'extra-big', 'fullwidth', ''].includes(value)
      },
      default: ''
    },
    color: {
      validator (value) {
        return ['purple-gradient', 'outline-grey'].includes(value)
      }
    },
    additionalClass: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses() {
      return [
        'x-button',
        this.size,
        this.color,
        this.additionalClass,
        this.isLoading ? 'loading' : '',
        this.disabled ? 'disabled' : ''
      ].filter(Boolean).join(' ')
    }
  },
  // Timeout for button loading
  watch: {
    loading(newValue) {
      if (newValue) {
        this.isLoading = true;
      } else {
        setTimeout(() => {
          this.isLoading = false;
          this.timeoutId = null;
        }, 300);
      }
    }
  },
  methods: {
    clickOnButton() {
      if (!this.loading && !this.disabled) {
        this.$emit('onClick')
      }
    }
  }
}
</script>

<style lang="scss" src="./xButton.scss" scoped />
