<template>
  <pop-up :open="open" @close="$emit('closePopup')" class="pop-cropper">
    <h2 class="x-pop-title">Отредактируйте логотип</h2>
    <p class="x-pop-desc">
      Выбранная область будет видна клиентам при взаимодействии с компанией.
    </p>
    <div class="cropper-wrapper">
      <div :style="{backgroundImage: 'url(' + img + ')'}" class="cropper-image-background"></div>
      <cropper
        ref="cropper"
        class="cropper"
        background-class="cropper-background"
        :auto-zoom="true"
        :stencil-props="{aspectRatio: 1}"
        :src="img"
        :stencil-component="$options.components.CircleStencil"
      />
    </div>
    <x-button @click="uploadImage">
      Сохранить и продолжить
    </x-button>
  </pop-up>
</template>

<script>
import xButton from '@/components/xButton/xButton.vue';
import popUp from '@/components/popUp/popUp.vue';
import {Cropper, CircleStencil} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';


export default {
  name: 'xCropper',
  // eslint-disable-next-line vue/no-unused-components
  components: {popUp, xButton, Cropper, CircleStencil},
  props: {
    img: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false,
      required: true
    },
    type: {
      type: String,
    }
  },
  data() {
    return {
      popCropper: false,
    }
  },
  methods: {
    uploadImage() {
      const {canvas} = this.$refs.cropper.getResult();
      if (!canvas) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка при загрузке изображения',
          desc: 'Перезагрузите сайт и попробуйте снова',
          status: 'error'
        })
        return;
      }
      if (canvas.width < 200 || canvas.height < 200) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка размера',
          desc: 'Размер файла меньше 200х200px',
          status: 'error'
        })
        return;
      }
      canvas.toBlob(blob => {
        this.$emit('uploadImage', blob);
      }, this.type);

      this.$emit('closePopup');
    }
  }
}
</script>

<style lang="scss" src="./xCropper.scss"/>
