<template>
  <div class="avatar">
    <img :src="src" :alt="alt">
  </div>
</template>

<script>

export default {
  name: 'xAvatar',
  props: {
    alt: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" src="./xAvatar.scss" scoped/>
