<template>
  <div class="integrations-container connect-help">
    <div class="layout-1">
      <div class="integrations-connect">
        <h2 class="title integrations-title">Подключение бот-аккаунта VK</h2>
        <p class="connect-desc">Введите секретный ключ вашего бот-аккаунта (API token):</p>
        <div class="connect-item">
          <div class="connect-input">
            <textInput
              placeholder="Введите ваш секретный ключ"
              v-bind:defaultValue="key"
              @onChange="onChangeKey"
            />
          </div>
          <div class="connect-button">
            <xButton @click="saveKey">
              Сохранить
            </xButton>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-1 connect-help">
      <div class="connect-help-item">
        <div class="connect-help-header">
          <h2 class="title connect-help-title">
            Ссылка на минилендинг:
          </h2>
          <div class="link" @click="copyAction">
            {{ welcomeLink }}
            <icon name="copy" class="table-icon" style="margin-left: 5px;"/>
          </div>
        </div>
      </div>
      <div class="connect-help-item">
        <div class="connect-help-header">
          <h2 class="title connect-help-title">
            Чтобы подключить НОВЫЙ бот-аккаунт VK нужно:
          </h2>
          <a href="#" class="link">Видео-инструкция</a>
        </div>
        <p class="connect-help-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur sit sem nulla bibendum amet, feugiat quam. Est tortor, magna sapien, scelerisque congue velit in pulvinar. Urna, nibh fusce elit adipiscing euismod in. Tortor, orci aenean sed cras tellus a ornare tellus viverra. Tortor in ullamcorper nec in facilisis id et ateget pretium mauris ac.
        </p>
      </div>
      <div class="connect-help-item">
        <div class="connect-help-header">
          <h2 class="title connect-help-title">
            Чтобы подключить СУЩЕСТВУЮЩИЙ бот-аккаунт VK нужно:
          </h2>
          <a href="#" class="link">Видео-инструкция</a>
        </div>
        <p class="connect-help-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur sit sem nulla bibendum amet, feugiat quam. Est tortor, magna sapien, scelerisque congue velit in pulvinar. Urna, nibh fusce elit adipiscing euismod in. Tortor, orci aenean sed cras tellus a ornare tellus viverra. Tortor in ullamcorper nec in facilisis id et ateget pretium mauris ac.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import copy from 'copy-to-clipboard'

export default {
  name: 'settingsIntegrationsVK',
  components: {
    textInput,
    xButton
  },
  data () {
    return {
      key: ''
    }
  },
  computed: {
    welcomeLink () {
      return `${window.location.origin}/welcome/${this.$store.state.techStore.companyId || 0}`
    }
  },
  methods: {
    copyAction () {
      copy(this.welcomeLink)
      this.$store.commit('animateStore/animateNotification', { text: 'Скопировано' })
    },
    onChangeKey (key) {
      this.key = key

      this.$store.commit(
        'techStore/setFormChanged',
        {
          isChanged: true,
          callback: this.saveKey
        }
      )
    },
    saveKey () {
      fetch(window.host + 'users/integration/vk', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          value: this.key
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: false
          }
        )
        this.$store.commit('animateStore/animateNotification')
      }).catch(err => {
        this.$store.commit(
          'popupStore/show',
          { text: err, useFullWindow: false }
        )
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  created () {
    fetch(window.host + 'users/integration/vk', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      if (data.data) {
        this.key = data.data
      }
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<!-- SCSS from parent views. -->
<style lang="scss" src="../settingsIntegrations.scss" scoped />
