<template>
  <preloader-full-screen v-if="$store.state.techStore.loader" />
  <adminHeader :titleOld="titleOld" :loading="loading"/>
  <sidebar />
  <div class="page_body">
    <div class="container">
      <router-view @titleName="handleName" />
    </div>
  </div>
  <helper />
  <errorPopup/>
  <notification/>
</template>

<script>
import notification from '@/components/notification'
import adminHeader from '@/components/adminHeader'
import sidebar from '@/components/sidebar'
import helper from '@/components/helper'
import errorPopup from '@/components/errorPopup'
import preloaderFullScreen from '@/components/preloader/preloaderFullScreen';

export default {
  name: 'admin',
  components: {
    preloaderFullScreen,
    adminHeader,
    sidebar,
    helper,
    notification,
    errorPopup
  },
  data () {
    return {
      titleOld: '',
      loading: false,
      isLoadComplete: false
    }
  },
  methods: {
    handleName (name) {
      if (name === this.titleOld) {
        this.loading = false
      } else {
        this.loading = true
        setTimeout(function (scope) {
          scope.loading = false
        }, 100, this)
      }
      this.titleOld = name
      this.$store.commit(
        'techStore/setTitleName',
        {
          name
        }
      )
    },
    // handleLoadComplete() {
    //
    // }
  },
  created () {
    this.$store.commit('socketStore/start')

    // this.handleLoadComplete()
  }
}
</script>

<style scoped src="./admin.scss" lang="scss" />
