<template>
    <svg preserveAspectRatio="none"  viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="96" height="96" rx="48" fill="url(#paint0_linear_3953_4285)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.41 46.63C38.0194 41.1007 46.4482 37.5269 50.6963 35.7737C62.6989 30.7839 65.1938 29.9073 66.8122 29.8398C67.1493 29.8398 67.9585 29.9073 68.4979 30.3119C68.9025 30.649 69.0374 31.121 69.1048 31.4582C69.1722 31.7953 69.2397 32.5371 69.1722 33.1439C68.4979 39.9544 65.7333 56.6097 64.2498 64.2293C63.6429 67.4659 62.4292 68.5448 61.2829 68.6797C58.788 68.882 56.8325 66.9939 54.405 65.443C50.6289 62.9481 48.4711 61.3972 44.7625 58.9697C40.5143 56.1377 43.279 54.5868 45.7065 52.0918C46.3134 51.4175 57.4394 41.3704 57.6416 40.4264C57.6416 40.2915 57.7091 39.887 57.4394 39.6847C57.1696 39.4824 56.8325 39.5498 56.5628 39.6172C56.1582 39.6847 50.0895 43.7305 38.2891 51.6873C36.536 52.901 34.9851 53.4404 33.569 53.4404C32.0181 53.4404 29.0512 52.5639 26.826 51.8221C24.1288 50.9455 21.971 50.4735 22.1733 48.99C22.3082 48.2483 23.3871 47.4392 25.41 46.63Z" fill="white"/>
      <linearGradient id="paint0_linear_3953_4285" x1="2" y1="49.9652" x2="97.9305" y2="49.9652" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2AABEE"/>
        <stop offset="1" stop-color="#229ED9"/>
      </linearGradient>
    </svg>
</template>
