<template>
  <adminNotify align="left">
    <div class="import-notify">
      <h4 class="notify-title">
        Инструкция по импорту база клиентов:
      </h4>
      <p class="notify-instruction">
        1. Загрузите контакты из файла Excel. Система проверит, есть ли в базе такой клиент.
        <br>
        2. По клиентам, которых нет, вы можете отправить приветственное смс с приглашением присоединиться к программе лояльности.
      </p>
      <div class="notify-alert">
        ВАЖНО! Вы отправляете СМС на свой страх и риск. По закону у вас должно быть согласие на обработку персональных данных и отправку рекламных сообщений от этих клиентов.
      </div>
    </div>
  </adminNotify>
  <div class="import-header">
    <xFilter />
    <div class="import-search">
      <textInput placeholder="Введите номер телефона" >
        <template v-slot:before>
          <button class="search-button">
            <icon class="search-icon" name="search" />
          </button>
        </template>
      </textInput>
    </div>
    <div class="import-button">
      <xButton>
        <icon class="xbutton-icon" name="imporbaseSmall" />
        Импорт из Excel
      </xButton>
    </div>
  </div>
  <xTable v-bind="getTableData()" />
</template>

<script>
import adminNotify from '@/components/adminNotify'
import xFilter from '@/components/xFilter'
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import importBase from '@/views/importView/importBase/importBase.json'
import xTable from '@/components/xTable'

export default {
  name: 'importBase',
  components: {
    adminNotify,
    xFilter,
    textInput,
    xButton,
    xTable
  },
  methods: {
    getTableData () {
      return {
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: importBase,
        wordChecker: true,
        optionsX: [
          { name: 'Номер телефона', value: 'phone', id: 0 },
          { name: 'Присвоить статус', value: 'status', id: 1 },
          { name: 'Бонусов на аккаунте', value: 'bonusesNumber', id: 2 },
          { name: 'Наличие в базе', value: 'baseChecker', id: 3 },
          { name: 'Дата отправки СМС', value: 'smsStatus', id: 4 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Наименование сообщения', 'Присвоить статус', 'Бонусов на аккаунте', 'Наличие в базе', 'Дата отправки СМС'],
        namesForHeaderX: []
      }
    }
  }
}
</script>

<style lang="scss" src="./importBase.scss" scoped />
