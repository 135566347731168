<template>
  <div>
    <xFilter
      class="create-news-filter"
      :rowCount="rowCount"
      @onExcelExport="onExcelExport"
      @apply="requestRowCount"
      @reset="resetValues"
    >
      <template #filterName>
        <div>
          Условия отбора
        </div>
        <note note-position="bottom-to-right">
          По умолчанию выбраны все клиенты. Если вы введете какие-то условия отбора, сообщение будет отправлено только
          выбранным вами клиентам.
        </note>
      </template>
      <xFilterItem
        v-for="item in tags"
        :key="item.id"
        :inputNumber="item.number"
        :item="item"
        @logChange="filtering"/>
    </xFilter>
    <tags :tags="tags" :tagsCount="tagsCount" @deleteTag="deletingTag"/>
    <div class="layout-1">
      <div class="create-header create-block">
        <div class="x-input-item">
          <div class="input-name">Что сделать</div>
          <div class="input-content">
            <Multiselect
              v-model="form.action_type"
              :options="inputOptions.action"
              placeholder="Начислить бонусы и отправить сообщение"
              :canClear="false"
            />
          </div>
        </div>
        <div class="create-conditions" v-if="form.action_type === 'Начислить бонусы и отправить сообщение'">
          <div class="create-conditions-bonuses">
            <div class="x-input-item">
              <div class="input-name">Начислить клиенту</div>
              <div class="input-content">
                <textInput :default-value="form.bonuses" @onChange="form.bonuses = $event"
                           :options="{max: 1000000, min: 0.01, type: 'float'}"/>
              </div>
              <div class="create-conditions-afterword">бонусов</div>
            </div>
          </div>
          <div class="create-conditions-remove">
            <div class="x-input-item">
              <div class="input-name">Сгорают</div>
              <div class="input-content">
                <Multiselect
                  v-model="form.bonuses_burn"
                  :options="inputOptions.burn_bonuses_action"
                  placeholder="Да"
                  :canClear="false"
                />
              </div>
            </div>
          </div>
          <div class="create-conditions-term">
            <div class="x-input-item" v-if="form.bonuses_burn === 'Да'">
              <div class="input-name">Через</div>
              <div class="input-content">
                <textInput :default-value="form.burn_days" @onChange="form.burn_days = $event"
                           :options="{max: 365, min: 1, type: 'integer'}"/>
              </div>
              <div class="create-conditions-afterword">дней</div>
            </div>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Когда отправлять</div>
          <div class="input-content">
            <Multiselect
              v-model="form.send_type"
              :options="inputOptions.send_type"
              placeholder="Сразу"
              :canClear="false"
            />
          </div>
        </div>
        <div class="create-date-time" v-if="form.send_type === 'Запланировать'">
          <div class="x-input-item input-date">
            <div class="input-name">Дата отправки</div>
            <div class="input-content">
              <div class="input-container">
                <input type="date" :value="form.send_date" @change="form.send_date = $event.target.value">
              </div>
            </div>
          </div>
          <div class="x-input-item input-time">
            <div class="input-name">Время</div>
            <div class="input-content">
              <div class="input-container">
                <input type="time" :value="form.send_time" @change="form.send_time = $event.target.value">
              </div>
            </div>
          </div>
        </div>

        <div class="create-header-total" v-if="rowCount">
          В выборку попадают
          <a :href="getClientsUrl" class="link" target="_blank">{{ rowCount }}</a>
          из {{ allClients }}
        </div>
      </div>
      <div class="create-block">
        <x-drag-and-drop :image="form.image" @uploaded="(e)=>{this.form.image = e[0]}"/>
      </div>
      <div class="create-message create-block">
        <div class="x-input-item x-input-item-column">
          <div class="input-name" id="input-title">Заголовок</div>
          <div class="input-content">
            <textInput @onChange="form.title = $event"
                       :default-value="form.title || ''"
                       placeholder="Напишите название сообщения, например “К нам приехала коллекция”"
                       :validate="!textValidation.title"
                       validate-text="Заголовок не может быть пустым"/>
          </div>
        </div>
        <div class="create-message-send create-text">
          <div class="note-wrapper">
            <h2 class="title">
              Текст сообщения
            </h2>
            <note note-position="bottom-to-right">
              Вы можете использовать переменные из списка ниже (автополя):
              <br>
              <span class="orange">@Имя_клиента</span> – имя клиента
              <br>
              <span class="orange">@Сэкономлено_сумма</span> – сумма, которую клиент оплатил бонусами за всю свою
              историю
              <br>
              <span class="orange">@Валюта_сокр</span> – сокращенное обозначение валюты
              <br>
              <span class="orange">@Бонусов_на_счете_сумма</span> – сумма бонусов на счете клиента
              <br>
              <span class="orange">@Текущий_статус</span> – текущий статус клиента
              <br>
              <span class="orange">@Бонус_свои_текущий_%</span> - размер бонуса за покупки клиента
              <br>
              <span class="orange">@Сумма_покупок_до_следующего</span> – сумма покупок для
              получения следующего статуса
              <br>
              <span class="orange">@К-во_рекомендаций_до_следующего</span> – количество рекомендаций для получения
              следующего статуса
              <br>
              <span class="orange">@Следующий_статус</span> – название следующего статуса
              <br>
              <span class="orange">@Бонус_свои_следующий_%</span> - размер бонуса, который будет начисляться клиенту в
              следующем статусе
              <br>
              <span class="orange">@Сумма_бонусов_начислено</span> - сумма бонусов, которая начисляется вместе с этим
              сообщением (если бонусы не начисляются, автополе не будет работать)
              <br>
              <span class="orange">@ВремяДата_сгорания_начисленных_бонусов</span> - дата и время сгорания бонусов,
              которые начисляются вместе с этим
              сообщением (если бонусы не начисляются, автополе не будет работать)
              <br><br>
              Переменные позволяют делать максимально персональные сообщения.
              <br><br>
              Перед массовой отправкой отправьте тестовое сообщение себе.
            </note>
          </div>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper superText-container">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" id="input-superText1" ref="superText1"
                       contenteditable="true"></div>
                  <template v-if="!textValidation.superText1">
                    <div class="validate-icon">
                      <icon name="validate"/>
                    </div>
                    <div class="error">
                      Текст не может быть пустым
                    </div>
                  </template>
                </div>
              </div>
              <div class="clicker-tech">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Имя_Клиента</span>')"
                  class="link clicker-tech-link" type="button" value="@Имя_Клиента">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Бонусов_на_счете_сумма</span>')"
                  class="link clicker-tech-link" type="button" value="@Бонусов_на_счете_сумма">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Текущий_статус</span>')"
                  class="link clicker-tech-link" type="button" value="@Текущий_статус">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Бонус_свой_текущий_%</span>')"
                  class="link clicker-tech-link" type="button" value="@Бонус_свой_текущий_%">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сэкономлено_сумма</span>')"
                  class="link clicker-tech-link" type="button" value="@Сэкономлено_сумма">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Валюта_сокр</span>')"
                  class="link clicker-tech-link" type="button" value="@Валюта_сокр">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_покупок_до_следующего</span>')"
                  class="link clicker-tech-link" type="button" value="@Сумма_покупок_до_следующего">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@К-во_рекомендаций_до_следующего</span>')"
                  class="link clicker-tech-link" type="button" value="@К-во_рекомендаций_до_следующего">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Следующий_статус</span>')"
                  class="link clicker-tech-link" type="button" value="@Следующий_статус">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Бонус_свои_следующий_%</span>')"
                  class="link clicker-tech-link" type="button" value="@Бонус_свои_следующий_%">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_бонусов_начислено</span>')"
                  class="link clicker-tech-link" type="button" value="@Сумма_бонусов_начислено">
                <input
                  @click="this.$refs.superText1.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@ВремяДата_сгорания_начисленных_бонусов</span>')"
                  class="link clicker-tech-link" type="button" value="@ВремяДата_сгорания_начисленных_бонусов">
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button color="outline-grey" class="arrow-hover" size="extra-small" @click="fetchTest('superText1')"
                      :loading="loading.superText1">
              Отправить тест
              <x-arrow/>
            </x-button>
          </div>
        </div>
      </div>
      <div class="create-message-reminder create-block"
           v-show="form.action_type === 'Начислить бонусы и отправить сообщение'">
        <div class="x-input-item">
          <div class="input-name">Сообщить о скором сгорании бонусов</div>
          <div class="input-content">
            <Multiselect
              v-model="form.notify_before_bonuses_burn"
              :options="inputOptions.notify_before_bonuses_burn"
              placeholder="Нет"
              :canClear="false"
            />
          </div>
          <template v-if="form.notify_before_bonuses_burn === 'Да'">
            <div class="create-conditions-afterword">отправить сообщение за</div>
            <div class="input-content">
              <textInput :options="{type: 'integer', min: 1, max: 30}" @onChange="form.nbbb_days = $event"
                         :default-value="form.nbbb_days"/>
            </div>
            <div class="create-conditions-afterword">дней</div>
          </template>
        </div>
        <div class="create-message-send" v-show="form.notify_before_bonuses_burn === 'Да'">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper superText-container">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" id="input-superText2"
                       ref="superText2"
                       contenteditable="true"></div>
                  <template v-if="!textValidation.superText2">
                    <div class="validate-icon">
                      <icon name="validate"/>
                    </div>
                    <div class="error">
                      Текст не может быть пустым
                    </div>
                  </template>
                </div>
              </div>
              <div class="clicker-tech">
                <input
                  @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Имя_Клиента</span>')"
                  class="link clicker-tech-link" type="button" value="@Имя_Клиента">
                <input
                  @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@ВремяДата_сгорания_начисленных_бонусов</span>')"
                  class="link clicker-tech-link" type="button" value="@ВремяДата_сгорания_начисленных_бонусов">
                <input
                  @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_бонусов_начислено</span>')"
                  class="link clicker-tech-link" type="button" value="@Сумма_бонусов_начислено">
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button color="outline-grey" class="arrow-hover" size="extraSmall" @click="fetchTest('superText2')"
                      :loading="loading.superText2">
              Отправить тест
              <x-arrow/>
            </x-button>
          </div>
        </div>
      </div>
      <div class="create-message-notify create-block"
           v-show="form.action_type === 'Начислить бонусы и отправить сообщение'">
        <div class="x-input-item">
          <div class="input-name">Сообщить о факте сгорании бонусов</div>
          <div class="input-content">
            <Multiselect
              v-model="form.notify_after_bonuses_burn"
              :options="inputOptions.notify_after_bonuses_burn"
              placeholder="Нет"
              :canClear="false"
            />
          </div>
        </div>
        <div class="create-message-send" v-show="form.notify_after_bonuses_burn === 'Да'">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <div class="create-message-body">
            <div class="create-message-body">
              <div class="text-wrapper" contenteditable='false'>
                <div class="input-wrapper superText-container">
                  <div class="input-container ">
                    <div class="input-clicker" @click="deleteText"
                         id="input-superText3" ref="superText3"
                         contenteditable="true"></div>
                    <template v-if="!textValidation.superText3">
                      <div class="validate-icon">
                        <icon name="validate"/>
                      </div>
                      <div class="error">
                        Текст не может быть пустым
                      </div>
                    </template>
                  </div>
                </div>
                <div class="clicker-tech">
                  <input
                    @click="this.$refs.superText3.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Имя_Клиента</span>')"
                    class="link clicker-tech-link" type="button" value="@Имя_Клиента">
                  <input
                    @click="this.$refs.superText3.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@ВремяДата_сгорания_начисленных_бонусов</span>')"
                    class="link clicker-tech-link" type="button" value="@ВремяДата_сгорания_начисленных_бонусов">
                  <input
                    @click="this.$refs.superText3.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_бонусов_начислено</span>')"
                    class="link clicker-tech-link" type="button" value="@Сумма_бонусов_начислено">
                </div>
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button color="outline-grey" class="arrow-hover" size="extraSmall" @click="fetchTest('superText3')"
                      :loading="loading.superText3">
              Отправить тест
              <x-arrow/>
            </x-button>
          </div>
        </div>
      </div>
      <div class="create-message-footer">
        <div class="create-message-footer__save">
          <xButton @click="createNews" :loading="loading.save">Сохранить</xButton>
        </div>
        <button class="link" @click="$router.go(-1)">Закрыть</button>
      </div>
    </div>
  </div>

</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import note from '@/components/note'
import xFilterItem from '@/components/xFilterItem';
import xFilter from '@/components/xFilter';
import tags from '@/components/tags';
import xDragAndDrop from '@/components/xDragAndDrop';
import {fetchData} from '@/rest/api';
import {adaptNews, scrollTo} from '@/utils';
import xArrow from '@/components/xArrow';


export default {
  components: {
    xArrow,
    xDragAndDrop,
    tags,
    xFilter,
    xFilterItem,
    textInput,
    xButton,
    note
  },
  name: 'messagesCreateNews',
  data() {
    return {
      mode: 'create',
      tagsCount: 0,
      rowCount: 0,
      tags: [
        {
          name: 'Пол',
          number: 2,
          type: 'checkbox',
          code: 'gender',
          filterText1: 'Мужской пол',
          filterText2: 'Женский пол',
          text1: 'Мужской',
          text2: 'Женский',
          value1: 'male',
          value2: 'female',
        },
        {
          name: 'Средний чек',
          number: 3,
          type: 'checkbox',
          code: 'averageCheck',
          filterText1: 'Высокий средний чек',
          filterText2: 'Средний средний чек',
          filterText3: 'Низкий средний чек',
          text1: 'Высокий средний чек',
          text2: 'Средний средний чек',
          text3: 'Низкий средний чек',
          value1: 'high',
          value2: 'middle',
          value3: 'low'
        },
        {
          name: 'Частота покупок',
          number: 3,
          type: 'checkbox',
          code: 'frequency',
          filterText1: 'Покупает часто',
          filterText2: 'Покупает не так часто',
          filterText3: 'Покупает редко',
          text1: 'Покупает часто',
          text2: 'Не так часто',
          text3: 'Редко',
          value1: 'often',
          value2: 'not_so_often',
          value3: 'rarely'
        },
        {
          name: 'Давность покупки',
          number: 3,
          type: 'checkbox',
          code: 'prescription',
          filterText1: 'Покупал недавно',
          filterText2: 'Покупал не так давно',
          filterText3: 'Покупал давно',
          text1: 'Недавно',
          text2: 'Не так давно',
          text3: 'Давно',
          value1: 'recently',
          value2: 'not_so_long_ago',
          value3: 'long_ago'
        },
        {
          name: 'Канал связи',
          number: 3,
          type: 'checkbox',
          code: 'channel',
          text1: 'Телеграмм',
          text2: 'ВКонтанке',
          text3: 'Viber',
          value1: 'telegram',
          value2: 'vk',
          value3: 'viber'
        },
        {
          name: 'Статус',
          number: 5,
          type: 'checkbox',
          code: 'clientStatus',
        },
        {
          name: 'Дата регистрации',
          type: 'date',
          code: 'register_date'
        },
        {
          name: 'Количество покупок',
          type: 'number',
          code: 'buy_count'
        },
        {
          name: 'Сумма покупок',
          type: 'number',
          code: 'purchasesNumber'
        },
        {
          name: 'Источник',
          number: 3,
          type: 'input',
          code: 'referralName',
          value: '',
          output: ''
        },
        {
          name: 'Возраст',
          type: 'number',
          code: 'age',
          value: '',
          output: ''
        },
        {
          name: 'Последняя покупка',
          type: 'date',
          code: 'last_buy'
        },
        {
          name: 'Количество бонусов',
          type: 'number',
          code: 'bonuses_amount',
          value: '',
          output: ''
        },
        {
          name: 'Уровень',
          type: 'number',
          code: 'level',
          value: '',
          output: ''
        }
      ],
      sort: {
        name: 'Дата регистрации',
        type: 'desc'
      },
      searchQuery: '',
      tableData: {
        optionsX: [
          {name: 'Клиент', value: 'name', id: 0},
          {name: 'Пол', value: 'gender', id: 1},
          {name: 'Дата регистрации', value: 'regDate', id: 2},
          {name: 'Уровень', value: 'level', id: 3},
          {name: 'Кол-во рекомендаций', value: 'reactionNumber', id: 4},
          {name: 'Остаток бонусов', value: 'bonusNumber', id: 5},
          {name: 'Сумма покупок', value: 'purchasesNumber', id: 6},
          {name: 'Источник', value: 'referralName', id: 7},
          {name: 'Статус', value: 'clientStatus', id: 8},
          {name: 'Группа Средний чек', value: 'averageCheck', id: 9},
          {name: 'Группа частота', value: 'averageFreq', id: 10},
          {name: 'Группа давность', value: 'averageTerm', id: 11},
          {name: 'Канал связи', value: 'channel', id: 12},
          {name: 'ID', value: 'clientID', id: 13}
        ]
      },
      value: null,
      filter: [],
      inputOptions: {
        action: [
          'Начислить бонусы и отправить сообщение',
          'Отправить сообщение'
        ],
        send_type: [
          'Сразу',
          'Запланировать'
        ],
        burn_bonuses_action: [
          'Да',
          'Нет'
        ],
        notify_before_bonuses_burn: [
          'Да',
          'Нет'
        ],
        notify_after_bonuses_burn: [
          'Да',
          'Нет'
        ],
      },
      allClients: 0,
      form: {
        action_type: 'Начислить бонусы и отправить сообщение',
        bonuses: 500.00,
        bonuses_burn: 'Да',
        burn_days: 7,
        send_type: 'Сразу',
        send_date: '',
        send_time: '',
        image: null,
        title: '',
        text: '',
        notify_before_bonuses_burn: 'Нет',
        nbbb_days: 3,
        nbbb_text: '',
        notify_after_bonuses_burn: 'Нет',
        nabb_text: '',
      },
      textValidation: {
        superText1: true,
        superText2: true,
        superText3: true,
        title: true
      },
      // Button loaders
      loading: {
        superText1: false,
        superText2: false,
        superText3: false,
        save: false
      }
    }
  },
  methods: {
    getRequestForm() {
      this.form.text = this.$refs.superText1.innerHTML === '<br>' ? '' : this.$refs.superText1.innerHTML
      this.form.nbbb_text = this.$refs.superText2?.innerHTML === '<br>' ? '' : this.$refs.superText2.innerHTML
      this.form.nabb_text = this.$refs.superText3?.innerHTML === '<br>' ? '' : this.$refs.superText3.innerHTML

      const formToSend = adaptNews(JSON.parse(JSON.stringify(this.form)), true)

      formToSend['image'] = this.form.image || ''


      const requestForm = new FormData()

      Object.keys(formToSend).forEach((key) => {
        requestForm.append(key, formToSend[key])
      })

      requestForm.append('filter', JSON.stringify({
        filter_autocomplete: this.filter,
        filter_request: this.getFilters.filter
      }))
      return requestForm
    },
    async fetchTest(textMessage) {
      let url = ''
      const requestForm = this.getRequestForm()
      this.textValidation.superText1 = true
      this.textValidation.superText2 = true
      this.textValidation.superText3 = true
      this.textValidation.title = true

      if (!requestForm.get('title')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните заголовок',
          desc: '',
          status: 'error'
        })
        scrollTo('#input-title')
        this.textValidation.title = false
        return
      }

      switch (textMessage) {
        case 'superText1': {
          url = 'news/test/message'
          if (!requestForm.get('text')) {
            this.textValidation.superText1 = false
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка, заполните текст сообщения',
              desc: '',
              status: 'error'
            })
            scrollTo('#input-superText1')
            return
          }
          this.loading.superText1 = true
          break;
        }
        case 'superText2': {
          url = 'news/test/n1'
          if (!requestForm.get('nbbb_text')) {
            this.textValidation.superText2 = false
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка, заполните сообщение о скором сгорании бонусов',
              desc: '',
              status: 'error'
            })
            scrollTo('#input-superText2')
            return
          }
          this.loading.superText2 = true
          break;
        }
        case 'superText3': {
          url = 'news/test/n2'
          if (!requestForm.get('nabb_text')) {
            this.textValidation.superText3 = false
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка, заполните сообщение о факте сгорании бонусов',
              desc: '',
              status: 'error'
            })
            scrollTo('#input-superText3')
            return
          }
          this.loading.superText3 = true
          break;
        }
      }

      const resp = await fetchData(url, {
        method: 'POST',
        body: requestForm
      })
      if (resp?.success) {
        this.$store.commit('animateStore/animateNotification', {text: 'Тест успешно отправлен'})
      } else {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка при отправке теста',
          desc: resp.message,
          status: 'error'
        })
      }
      this.loading.superText1 = false
      this.loading.superText2 = false
      this.loading.superText3 = false
    },
    async createNews() {
      const requestForm = this.getRequestForm()

      this.textValidation.superText1 = true
      this.textValidation.superText2 = true
      this.textValidation.superText3 = true
      this.textValidation.title = true

      if (!requestForm.get('title')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните заголовок',
          desc: '',
          status: 'error'
        })
        scrollTo('#input-title')
        this.textValidation.title = false

        return
      }
      if (!requestForm.get('text')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните текст',
          desc: '',
          status: 'error'
        })
        scrollTo('#input-superText1')
        this.textValidation.superText1 = false
        return
      }
      if (this.form.notify_before_bonuses_burn === 'Да' && !requestForm.get('nbbb_text')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните текст',
          desc: '',
          status: 'error'
        })
        scrollTo('#form-text-2')
        this.textValidation.superText2 = false
        return
      }
      if (this.form.notify_after_bonuses_burn === 'Да' && !requestForm.get('nabb_text')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните текст',
          desc: '',
          status: 'error'
        })
        scrollTo('#form-text-3')
        this.textValidation.superText3 = false
        return
      }

      this.loading.save = true

      if (this.mode === 'create') {
        const resp = await fetchData('news', {
          method: 'POST',
          body: requestForm
        })
        if (resp?.success) {
          this.$router.push('/admin/messages/news')
          this.$store.commit('animateStore/animateNotification', {text: 'Новость успешно создана'})
        } else {
          this.$store.commit('animateStore/animateNotification', {
            text: 'Ошибка при создании новости',
            desc: resp.message,
            status: 'error'
          })
          this.loading.save = false
        }
      } else {
        const newsId = this.$route.params.id
        requestForm.append('_method', 'PUT')
        const resp = await fetchData(`news/${newsId}`, {
          method: 'POST',
          body: requestForm
        })
        if (resp?.success) {
          this.$router.push('/admin/messages/news')
          this.$store.commit('animateStore/animateNotification')
        } else {
          this.$store.commit('animateStore/animateNotification', {
            text: 'Ошибка при изменении новости',
            desc: resp.message,
            status: 'error'
          })
          this.loading.save = false
        }
      }


    },
    requestRowCount() {
      fetch(window.host + 'news/getCountClients', {
        method: 'POST',
        body: JSON.stringify({filter: this.getFilters.filter}),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.rowCount = data.data?.countClientsSelect || 0
        this.allClients = data.data?.countClientsAll || 0

      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    filtering(value, name, direct, mode = true) {

      const filterIndex = this.filter.findIndex((item) => item.name === name && item.direct === direct)
      if (filterIndex === -1) {
        this.filter.push({value, name, direct})
      } else {
        this.filter[filterIndex].value = value
      }

      if (value === '') {
        this.deletingTag(name, direct)
        this.filter.splice(filterIndex, 1)
      } else if (!value) {
        this.deletingTag(name, direct)
        this.filter.splice(filterIndex, 1)
      } else {
        this.tagsCount += 1

        const selectedTagIndex = this.tags.findIndex(el => el.name === name)

        if (selectedTagIndex !== -1 && this.tags[selectedTagIndex].type === 'checkbox') {
          const filterName = `${this.tags[selectedTagIndex].name}: `
          const selectedDirect = []

          let maxI = 0

          for (const key of Object.keys(this.tags[selectedTagIndex])) {
            const onlyDigits = key.replace(/\D/gmu, '')
            if (onlyDigits.length) {
              const digitsInt = parseInt(onlyDigits)
              if (digitsInt > maxI) {
                maxI = digitsInt
              }
            }
          }

          if (maxI) {
            for (let i = 1; i <= maxI; i++) {
              const checkKey = `check${i}`
              if (this.tags[selectedTagIndex][checkKey] || checkKey === direct) {
                this.tags[selectedTagIndex][`check${i}`] = true
                selectedDirect.push(this.tags[selectedTagIndex][`text${i}`])

              }
            }
          }

          this.tags[selectedTagIndex].output = `${filterName} ${selectedDirect.join(', ')}`

        } else if (selectedTagIndex !== -1 && this.tags[selectedTagIndex].type === 'input') {
          this.tags[selectedTagIndex].value = value
          this.tags[selectedTagIndex].output = `${this.tags[selectedTagIndex].name}: ${value}`
        } else {
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === name) {
              const fromVal = direct === 'from' ? value : (this.tags[i].valueFrom ? this.tags[i].valueFrom : '')
              const toVal = direct === 'to' ? value : (this.tags[i].valueTo ? this.tags[i].valueTo : '')

              this.tags[i].valueFrom = fromVal
              this.tags[i].valueTo = toVal

              const prefixFrom = fromVal ? 'от' : ''
              const prefixTo = toVal ? 'до' : ''

              const output = `${name}: ${prefixFrom} ${fromVal} ${prefixTo} ${toVal}`
              this.tags[i].output = output.replace('  ', ' ')
            }
          }
        }
      }
      if (mode) {
        this.requestRowCount()
      }
    },
    onExcelExport() {
      fetch(window.host + 'excel/code', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (!data.data) {
          throw Error('Нет авторизационного кода')
        }

      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    resetValues() {
      this.tagsCount = 0
      for (let i = 0; i < this.tags.length; i++) {
        this.tags[i].value = ''
        this.tags[i].valueTo = ''
        this.tags[i].valueFrom = ''
        this.tags[i].output = ''

        let maxI = 0
        for (const key of Object.keys(this.tags[i])) {
          const onlyDigits = key.replace(/\D/gmu, '')
          if (onlyDigits.length) {
            const digitsInt = parseInt(onlyDigits)
            if (digitsInt > maxI) {
              maxI = digitsInt
            }
          }
        }

        if (maxI) {
          for (let j = 1; j <= maxI; j++) {
            this.tags[i][`output${j}`] = ''
            this.tags[i][`check${j}`] = false
          }
        }
      }

      this.requestRowCount()
    },
    deletingTag(name, direct) {
      this.tagsCount -= 1
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name === name) {

          if (this.tags[i].type === 'checkbox') {
            let maxI = 0
            for (const key of Object.keys(this.tags[i])) {
              const onlyDigits = key.replace(/\D/gmu, '')
              if (onlyDigits.length) {
                const digitsInt = parseInt(onlyDigits)
                if (digitsInt > maxI) {
                  maxI = digitsInt
                }
              }
            }

            if (!maxI) {
              return
            }
            for (let j = 1; j <= maxI; j++) {
              if (direct === `check${j}` || !direct) {
                this.tags[i][`check${j}`] = false
                this.tags[i][`output${j}`] = ''
                const filterIndex = this.filter.findIndex((item) => item.name === name && item.direct === `check${j}`)
                if (filterIndex >= 0) {
                  this.filter.splice(filterIndex, 1)
                }
              }
            }
            let counter = 0
            const selectedDirect = []
            for (let j = 1; j <= maxI; j++) {
              if (this.tags[i][`check${j}`]) {
                counter++
                selectedDirect.push(this.tags[i][`text${j}`])
              }
            }
            if (counter) {
              this.tags[i].output = `${name}: ${selectedDirect.join(', ')}`
            } else {
              this.tags[i].output = ''
            }


          } else if (this.tags[i].type === 'input') {
            this.tags[i].output = ''
            this.tags[i].value = ''
            const filterIndex = this.filter.findIndex((item) => item.name === name)
            if (filterIndex >= 0) {
              this.filter.splice(filterIndex, 1)
            }
          } else {
            this.tags[i].output = ''
            this.tags[i].valueTo = ''
            this.tags[i].valueFrom = ''
            const filterIndex1 = this.filter.findIndex((item) => item.name === name)

            if (filterIndex1 >= 0) {
              this.filter.splice(filterIndex1, 1)
            }
            const filterIndex2 = this.filter.findIndex((item) => item.name === name)
            if (filterIndex2 >= 0) {
              this.filter.splice(filterIndex2, 1)
            }
          }
        }
      }

      this.requestRowCount()
    },
    insertText(html, selectPastedContent) {
      let sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment();
          let node;
          let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          const firstNode = frag.firstChild
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            if (selectPastedContent) {
              range.setStartBefore(firstNode)
            } else {
              range.collapse(true)
            }
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange()
        originalRange.collapse(true)
        sel.createRange().pasteHTML(html)
        if (selectPastedContent) {
          range = sel.createRange()
          range.setEndPoint('StartToStart', originalRange)
          range.select()
        }
      }
    },
    deleteText(e) {
      if (e.target.className === 'link clicker-link') {
        e.target.innerHTML = ''
      }
    }
  },
  computed: {
    getClientsUrl() {
      const filter = JSON.parse(JSON.stringify(this.filter))
      for (let i = 0; i < filter.length; i++) {
        filter[i] = `{"direct":"${filter[i].direct}","name":"${filter[i].name}","value":"${filter[i].value}"}`
      }

      return `/admin/clients?filter_autocomplete=[${filter.join(',')}]`
    },
    getFilters() {
      const filter = {}

      for (const i in this.tags) {
        const tag = this.tags[i]

        if (tag.type === 'checkbox') {
          // eslint-disable-next-line no-case-declarations
          const tagValue = []

          let maxI = 0
          for (const key of Object.keys(tag)) {
            const onlyDigits = key.replace(/\D/gmu, '')
            if (onlyDigits.length) {
              const digitsInt = parseInt(onlyDigits)
              if (digitsInt > maxI) {
                maxI = digitsInt
              }
            }
          }

          if (maxI) {
            for (let j = 1; j <= maxI; j++) {
              if (tag[`check${j}`]) {
                if (tag.name === 'Статус') {
                  tagValue.push(tag[`id${j}`])
                } else {
                  tagValue.push(tag[`value${j}`])
                }
              }
            }
          }

          if (tagValue.length) {
            filter[tag.code] = tagValue
          }
        } else if (tag.type === 'input') {
          if (tag.value.trim().length) {
            filter[tag.code] = tag.value.trim()
          }
        } else {
          if (tag.valueFrom) {
            filter[`${tag.code}_from`] = tag.valueFrom
          }

          if (tag.valueTo) {
            filter[`${tag.code}_to`] = tag.valueTo
          }
        }
      }
      if (filter.clientStatus) {

      }
      const result = {
        searchQuery: this.searchQuery,
        filter
      }

      const sortField = this.tableData.optionsX.find(el => el.name === this.sort.name)
      if (sortField) {
        result.sort = {
          name: sortField.value,
          type: this.sort.type
        }
      }

      return result
    },
    cleanedRowCount() {
      return this.rowCount.replace(/\D/g, ''); // Убираем всё, кроме чисел
    }
  },
  async mounted() {
    const newsId = this.$route.params.id
    if (this.$route.name === 'editNews' && newsId) {
      this.mode = 'edit'
      const {data} = await fetchData(`news/${newsId}/edit`, {
        method: 'GET',
      })
      if (data.filter) {
        const {filter_autocomplete} = JSON.parse(data.filter)
        this.filter = filter_autocomplete
        if (Array.isArray(this.filter)) {
          this.filter.forEach((item) => {
            this.filtering(item.value, item.name, item.direct, false)
          })
        }
      }

      this.form = adaptNews(data, false)
      if (data) {
        this.$refs.superText1.innerHTML = this.form.text
        this.$refs.superText2.innerHTML = this.form.nbbb_text
        this.$refs.superText3.innerHTML = this.form.nabb_text
      }
    } else {
      this.$refs.superText2.innerHTML = 'Ваши подарочные бонусы скоро сгорят <span contenteditable=\'false\' class=\'link clicker-link\'>@ВремяДата_сгорания_начисленных_бонусов</span>, чтобы подарочные бонусы в сумме <span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_бонусов_начислено</span> не сгорели'
      this.$refs.superText3.innerHTML = 'Подарочные бонусы на вашем счете сгорели, т.к. вы так и не сделали покупку.'
    }

    const {data} = await fetchData('news/getStatusesList', {
      method: 'POST',
    })
    if (data) {
      const index = this.tags.findIndex((tag) => tag.name === 'Статус')
      for (let i = 0; i < data.statuses.length; i++) {
        this.tags[index]['value' + (i + 1)] = data.statuses[i].name
        this.tags[index]['text' + (i + 1)] = data.statuses[i].name
        this.tags[index]['id' + (i + 1)] = data.statuses[i].id
      }

      this.tags[index].number = data.statuses.length

      if (Array.isArray(this.filter)) {
        this.filter.forEach((item) => {
          this.filtering(item.value, item.name, item.direct, false)
        })
      }

    }
    this.requestRowCount()

  }
}
</script>

<style lang="scss" src="../messages.scss" scoped/>
<style lang="scss" src="../../../components/textInput/textInput.scss" scoped/>
<style lang="scss" src="./messagesManageNews.scss" scoped/>
