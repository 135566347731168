<template>
  <label class="toggle-switch" :class="{'loading': isLoading}">
    <input type="checkbox" :checked="checked" :disabled="isLoading" @change="$emit('change', $event.target.checked)"/>
    <span class="switcher"></span>
  </label>
</template>

<script>
export default {
  name: 'xSwitch',
  emits: ['change'],
  data(){
    return {
      isLoading: false
    }
  },
  props: {
    checked: {
      type: Boolean
    },
    loading: Boolean
  },
  watch: {
    loading(value) {
      if (value) {
        this.isLoading = true;
      } else {
        setTimeout(() => {
          this.isLoading = false;
        }, 300);
      }
    }
  }
}
</script>

<style lang="scss" src="./xSwitch.scss" />
