<template>
  <div class="filter-container">
    <button @click="toggle" class="filter-button">
      <div :class="['filter-button-line-container', {'filter-active': isOpened}]">
        <div class="filter-button-line"></div>
        <div class="filter-button-line"></div>
      </div>
      <div class="filter-button-text">
        <template v-if="isFilterNamePresent">
          <slot name="filterName" />
        </template>
        <template v-else>
          Фильтр
        </template>
      </div>
    </button>
    <transition name="filterOpened">
    <div v-if="isOpened" class="filter-content">
      <button class="filter-reset" @click='$emit("reset")'>
        <icon class="filter-reset-icon" name="reset" />
        Сбросить
      </button>
      <div class="filter-item-container">
        <slot />
      <button v-if="exportExcel" class="filter-export" @click="this.$emit('onExcelExport')">
        Экспорт в Excel
        <icon class="filter-export-icon" name="exportXLS" />
      </button>
      <div class="filter-status">
        <div class="filter-status-total">{{ !!rowCountString ? `Найдено ${rowCountString}` : ''}}</div>
        <button class="link" @click="toggle">Применить</button>
      </div>
    </div>
    </div>
    </transition>
  </div>
</template>

<script>
const pluralize = (count, words) => {
  const countN = parseInt(count)
  const cases = [2, 0, 1, 1, 1, 2]
  return count.toString() + ' ' + words[(countN % 100 > 4 && countN % 100 < 20) ? 2 : cases[Math.min(countN % 10, 5)]]
}

export default {
  name: 'xFilter',
  props: {
    checked: {
      type: Boolean
    },
    rowCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isOpened: false,
      exportExcel: false
    }
  },
  computed: {
    isFilterNamePresent () {
      return this.$slots.filterName && this.$slots.filterName().length > 0
    },
    rowCountString: function () {
      const count = this.rowCount.toString().replace(/\D/g, '')
      return pluralize(count, ['запись', 'записи', 'записей'])
    }
  },
  methods: {
    toggle () {
      this.isOpened = !this.isOpened
      this.$emit('apply')
    }
  }
}
</script>

<style lang="scss" src="./xFilter.scss" scoped />
