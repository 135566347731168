<template>
  <div class="layout-1">
    <div class="columns-2 profile-container profile-form">
      <div class="columns-2-left">
        <div class="x-input-item">
          <div class="input-name">Логин</div>
          <div class="input-content">
            <textInput :defaultValue="userData.email" :disabled="true"/>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Пароль</div>
          <div class="input-content">
            <textInput autocomplete="current-password" type="password" defaultValue="****************" :disabled="true">
              <template #after>
                <router-link to="/admin/change-password" class="link">
                  сменить
                </router-link>
              </template>
            </textInput>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Название</div>
          <div class="input-content">
            <textInput :defaultValue="userData.company" @onChange="val => changeSaveAction('company', val)"/>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Имя поддомена</div>
          <div class="input-content">
            <textInput :defaultValue="userData.subdomain" @onChange="val => changeSaveAction('subdomain', val)"/>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Вид деятельности</div>
          <div class="input-content">
            <Multiselect
              v-model="userData.activity"
              :options="activity"
              :canClear="false"
              :disabled="true"
            />
          </div>
        </div>
        <div class="x-input-item activity-input">
          <div class="input-name">
            <div class="note-wrapper activity-note">
              Вид деятельности
              <note note-position="bottom-to-right">«Укажите рыночное позиционирование вида деятельности вашей компании
                в именительном падеже (ответ на вопрос «что?»).<br/><br/> Например, «магазин товаров для животных». Это
                позиционирование по умолчанию используется в системных сообщениях (вы можете их отредактировать).
              </note>
            </div>
            <div class="activity-footnote">(именительный)</div>
          </div>
          <div class="input-content" :class="(userData.activityWhat || '').length > 200 && 'error-wrap'">
            <div class="input-wrapper">
              <div class="input-container">
                <input
                  class="input"
                  type="text"
                  v-model="userData.activityWhat"
                  @input="event => changeSaveAction('activityWhat', event.target.value)"
                />
              </div>
              <div v-if="(userData.activityWhat || '').length > 200" class="error error-input">
                Максимальная длина – 200 символов
              </div>
            </div>
          </div>
        </div>
        <div class="x-input-item activity-input">
          <div class="input-name">
            <div class="note-wrapper activity-note">
              Вид деятельности
              <note note-position="bottom-to-right">Укажите рыночное позиционирование вида деятельности вашей компании в
                форме ответа на вопрос «где?» без стартового предлога.<br/><br/> Например, «магазине товаров для
                животных». Это позиционирование по умолчанию используется в системных сообщениях (вы можете их
                отредактировать).
              </note>
            </div>
            <div class="activity-footnote">(ответ на вопрос «где?»)</div>
          </div>
          <div class="input-content" :class="(userData.activityWhere || '').length > 200 && 'error-wrap'">
            <div class="input-wrapper">
              <div class="input-container">
                <input
                  class="input"
                  type="text"
                  v-model="userData.activityWhere"
                  @input="event => changeSaveAction('activityWhere', event.target.value)"
                />
              </div>
              <div v-if="(userData.activityWhere || '').length > 200" class="error error-input">
                Максимальная длина – 200 символов
              </div>
            </div>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Валюта</div>
          <div class="input-content">
            <Multiselect
              v-model="currenciesValue"
              :options="currencies"
              :canClear="false"
              @change="val => changeSaveAction('currency_id', val)"
            />
          </div>
        </div>
      </div>
      <div class="columns-2-right">
        <div class="company-avatar">
          <div class="company-img">
            <x-avatar v-if="!this.$store.state.techStore.companyLogo" :src="require('@/assets/img/client-logo.svg')"
                      alt="Логотип компании"/>
            <x-avatar v-if="this.$store.state.techStore.companyLogo" :src="getCompanyLogo" alt="Логотип компании"/>
          </div>
          <div class="avatar-desc">
            <div class="avatar-filename">Логотип компании</div>
            <div class="avatar-size">Допустимые форматы: jpg, png (до 3 Мб).
              Минимальное разрешение - 200х200px. Рекомендуемое - 800х800px.
            </div>
            <label class="avatar-upload">
              <input accept=".jpg, .jpeg, .png" id="company-logo" type="file" ref="logoInput" @change="cropLogo">
              <a class="link">Загрузить новый</a>
            </label>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Часовой пояс</div>
          <div class="input-content">
            <Multiselect
              v-model="timezoneValue"
              :options="timezone"
              :canClear="false"
              @change="val => changeSaveAction('timezone_id', val)"
            />
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Телефон общий</div>
          <div class="input-content" :class="!userData.phone && 'error-wrap'">
            <div class="input-wrapper">
              <div class="input-container">
                <input
                  class="input"
                  type="text"
                  v-mask="phoneMask"
                  :placeholder="phonePlaceholder"
                  v-model="userData.phone"
                  @input="event => changeSaveAction('phone', event.target.value)"
                >
              </div>
              <div v-if="!userData.phone" class="error error-input">
                Телефон обязателен
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link class="instruction" to="/instruction" target='_blank'>
        <icon name="instruction" class="instruction-icon"/>
        <div class="link">Видеоинструкция по заполнению профиля компании</div>
      </router-link>
    </div>

    <div class="info-chat profile-container">
      <h3 class="title">Чат с клиентами</h3>
      <div class="info-chat-checkbox">
        <checkboxInput
          :checked="userData.chat === 1"
          :name="userData.chat ? 'Чат с клиентами включен' : 'Чат с клиентами выключен'"
          @isChecked="val => changeClientChat(val)"
        />
      </div>
    </div>

    <div class="info-branch profile-container">
      <div class="branch-header">
        <h3 class="title">Создайте минимум один филиал</h3>
        <button @click="openFilialPopup" class="link branch-header-link">Создать филиал</button>
      </div>
      <xTable v-bind="tableData">
        <template v-slot="{ records }">
          <td>
            <div class="table-action">
              <button @click="openFilialPopupEdit(records)">
                <icon name="edit" class="table-icon"/>
              </button>
              <button @click="openDelete(records)">
                <icon name="deleted" class="table-icon"/>
              </button>
            </div>
          </td>
        </template>
      </xTable>
    </div>
  </div>
  <pop-up :open="filialPopupOpen" @close="closeFilialPopup" :center="true">
    <h2 class="x-pop-title">
      {{ isFilialEdit ? 'Редактирование данных филиала' : 'Создание нового филиала' }}
    </h2>
    <div class="x-input-item">
      <div class="input-name">Рабочее название</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="filialPopupErrors.branchName && 'error-border error-border-abs'">
            <input
              class="input"
              type="text"
              :value="filialPopup.branchName"
              placeholder="Введите название"
              @input="changeBranchName"
            >
            <span class="error error-abs" v-if="filialPopupErrors.branchName">{{ filialPopupErrors.branchName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Адрес</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="filialPopupErrors.branchAddress && 'error-border error-border-abs'">
            <input
              class="input"
              type="text"
              :value="filialPopup.branchAddress"
              placeholder="Введите адрес"
              @input="changeBranchAddress"
            >
            <span class="error error-abs" v-if="filialPopupErrors.branchAddress">{{
                filialPopupErrors.branchAddress
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <xButton @click="saveFilialPopup">Сохранить</xButton>
  </pop-up>
  <pop-up :open="popUpDelete" @close="popUpDelete = !popUpDelete" sizer="pop-small">
    <h2 class="x-pop-title">Вы удаляете филиал</h2>
    <p class="x-pop-desc-black">
      Теперь вы не сможете добавлять в него кассиров, проводить по нему транзакции. Вся ранее накопленная статистика по
      этому филиалу сохранится.
      <br>
      Пожалуйста, подтвердите удаление
    </p>
    <div class="x-pop-footer x-pop-footer__delete">
      <xButton @click="filialDelete">Удалить</xButton>
      <button @click="popUpDelete = false" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>

  <!-- Logo cropper -->
  <x-cropper :img="uploadedLogo.src" :type="uploadedLogo.type" :open="popCropper" @uploadImage="saveLogo"
             @closePopup="handleCloseCropper"/>
</template>

<script>
import textInput from '@/components/textInput'
import checkboxInput from '@/components/checkboxInput'
import xTable from '@/components/xTable'
import popUp from '@/components/popUp'
import xButton from '@/components/xButton'
import debounce from 'lodash.debounce'
import Icon from '@/components/icons'
import Note from '@/components/note'
import xCropper from '@/components/xCropper'
import XAvatar from '@/components/xAvatar';

const saveChanges = debounce((fields, successCall) => {
  fetch(window.host + 'users/profile', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    },
    body: JSON.stringify(fields)
  }).then(async response => {
    const data = await response.json()

    if (!response.ok || !data.success) {
      const error = (data && data.message) || response.status
      return Promise.reject(error)
    }
  }).catch(err => {
    this.$store.commit('techStore/checkError', {err})
  })

  successCall()
}, 1000)

export default {
  name: 'settingsProfile',
  components: {
    XAvatar,
    Note,
    Icon,
    textInput,
    checkboxInput,
    xTable,
    popUp,
    xButton,
    xCropper
  },
  data() {
    return {
      isFilialEdit: false,
      filialPopup: {
        id: 0,
        branchName: '',
        branchAddress: ''
      },
      filialPopupErrors: {
        branchName: '',
        branchAddress: ''
      },
      userData: {
        email: '',
        company: '',
        subdomain: '',
        activity: '',
        activityWhat: '',
        activityWhere: '',
        phone: '',
        chat: 0
      },
      phoneMask: '',
      phonePlaceholder: '',
      filialPopupOpen: false,
      popUpDelete: false,
      popUpAvatar: true,
      currenciesValue: null,
      originalCurrencies: [],
      currencies: [],
      originalActivity: [],
      activity: [],
      timezoneValue: '',
      originalTimezone: [],
      timezone: [],
      deleteFilialId: false,
      tableData: {
        theads: 1,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          {
            name: 'Рабочее название',
            value: 'branchName',
            id: 0
          },
          {
            name: 'Адрес',
            value: 'branchAddress',
            id: 1
          }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Рабочее название', 'Адрес'],
        namesForHeaderX: []
      },
      companyForSaveData: {},
      uploadedLogo: {src: '', type: 'image/jpeg', name: ''},
      popCropper: false
    }
  },
  computed: {
    getCompanyLogo() {
      return window.domen + this.$store.state.techStore.companyLogo
    }
  },
  methods: {
    changeBranchName(e) {
      this.filialPopup.branchName = e.target.value
      this.filialPopupErrors.branchName = ''
    },
    changeBranchAddress(e) {
      this.filialPopup.branchAddress = e.target.value
      this.filialPopupErrors.branchAddress = ''
    },
    changeClientChat(value) {
      const correctValue = value ? 1 : 0
      this.userData.chat = correctValue
      this.changeSaveAction('chat', correctValue)
    },
    collectPhoneMask() {
      const split = this.userData.phone.split('(')
      const countryCode = split[0].trim()
      const otherPhonePart = ('(' + split[1].trim()).replace(/\d/gmu, '#')

      this.phoneMask = [countryCode, otherPhonePart].join(' ')
      this.phonePlaceholder = countryCode
    },
    changeSaveAction(field, value) {
      if (field === 'phone' && value.replace(/\D/gmu, '').trim().length !== 11) {
        return ''
      }

      if (field === 'activityWhat' && value.length > 200) {
        return
      }

      if (field === 'activityWhere' && value.length > 200) {
        return
      }

      // eslint-disable-next-line no-unreachable
      let correctValue = value

      switch (field) {
        case 'currency_id':
          // eslint-disable-next-line no-case-declarations
          const selectedCurrency = this.originalCurrencies.find(el => el.code === value)
          if (selectedCurrency) {
            correctValue = selectedCurrency.id
          }
          break
        case 'timezone_id':
          // eslint-disable-next-line no-case-declarations
          const selectedTimezone = this.originalTimezone.find(el => el.name === value)
          if (selectedTimezone) {
            correctValue = selectedTimezone.id
          }
          break
      }
      this.companyForSaveData[field] = correctValue

      saveChanges(
        this.companyForSaveData,
        () => {
          if (typeof this.companyForSaveData.company !== 'undefined') {
            this.$store.commit(
              'techStore/setCompanyData',
              {
                name: this.companyForSaveData.company
              }
            )
          }
          this.companyForSaveData = {}

          this.$store.commit('animateStore/animateNotification')
        }
      )
    },
    openFilialPopup() {
      this.filialPopupOpen = true
    },
    openFilialPopupEdit(data) {
      this.filialPopup = {...data}
      this.isFilialEdit = true
      this.filialPopupOpen = true
    },
    closeFilialPopup() {
      this.filialPopupOpen = false
      this.isFilialEdit = false
      this.filialPopup = {
        id: 0,
        branchName: '',
        branchAddress: ''
      }
    },
    saveFilialPopup() {
      if (!this.filialPopup.branchName.length) {
        this.filialPopupErrors.branchName = 'Заполните поле'
      }
      if (!this.filialPopup.branchAddress.length) {
        this.filialPopupErrors.branchAddress = 'Заполните поле'
      }

      if (this.filialPopupErrors.branchName.length || this.filialPopupErrors.branchAddress.length) {
        return
      }

      fetch(window.host + 'users/offices/' + (this.isFilialEdit ? this.filialPopup.id : ''), {
        method: this.isFilialEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          name: this.filialPopup.branchName,
          address: this.filialPopup.branchAddress
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (this.isFilialEdit) {
          const editI = this.tableData.data.findIndex(el => el.id === this.filialPopup.id)
          this.tableData.data[editI].branchName = data.data.name
          this.tableData.data[editI].branchAddress = data.data.address
        } else {
          this.tableData.data.push({
            id: data.data.id,
            branchName: data.data.name,
            branchAddress: data.data.address
          })
        }

        this.$store.commit('animateStore/animateNotification')
        this.closeFilialPopup()
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    openDelete(data) {
      this.deleteFilialId = data.id
      this.popUpDelete = true
    },
    filialDelete() {
      fetch(window.host + 'users/offices/' + this.deleteFilialId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        const deleteI = this.tableData.data.findIndex(el => el.id === this.deleteFilialId)
        this.tableData.data.splice(deleteI, 1)

        this.$store.commit('animateStore/animateNotification')
        this.popUpDelete = false
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    cropLogo() {
      const file = this.$refs.logoInput.files[0];
      this.$refs.logoInput.value = '';

      if (!file) return

      if (file.size / 1024 > 3072) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка при загрузке изображения',
          desc: 'Вес файла больше 3 Мб',
          status: 'error'
        })
        return
      }

      const reader = new FileReader();
      const img = new Image();

      reader.onload = (e) => {
        img.src = e.target.result;

        img.onload = () => {
          if (img.width < 200 || img.height < 200) {
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка при загрузке изображения',
              desc: 'Размер файла меньше 200х200px',
              status: 'error'
            })
            return
          }
          this.uploadedLogo.src = img.src;
          this.uploadedLogo.type = file.type;
          this.uploadedLogo.name = file.name;

          this.popCropper = true
        };
      }
      reader.readAsDataURL(file);
    },
    async saveLogo(image) {
      const formData = new FormData()
      formData.append('_method', 'put') // laravel bug

      formData.append('logotype', image, this.uploadedLogo.name)

      fetch(window.host + 'users/logotype', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: formData
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.data) {
          this.$store.commit(
            'techStore/setCompanyData',
            {
              path: data.data
            }
          )

          this.$store.commit('animateStore/animateNotification', {
            text: data.message
          })
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      }).finally(() => {

      })
    },
    handleCloseCropper() {
      this.popCropper = false
    },
  },
  created() {
    fetch(window.host + 'users/profile', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }
      const userData = data.data.user
      this.userData.email = userData.email
      this.userData.company = userData.company
      this.userData.subdomain = userData.subdomain
      this.userData.activityWhat = userData.activityWhat
      this.userData.activityWhere = userData.activityWhere
      this.userData.chat = userData.chat
      this.userData.phone = userData.phone

      this.collectPhoneMask()

      const filtredCurrencies = data.data.currencies.filter(el => el.active)
      this.originalCurrencies = filtredCurrencies
      this.currencies = filtredCurrencies.map(el => {
        if (userData.currency_id && userData.currency_id === el.id) {
          this.currenciesValue = el.code
        }
        return el.code
      })

      fetch(window.privateHost + 'activities', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(async response => {
        const dataActivity = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.originalActivity = dataActivity.data
        this.activity = dataActivity.data.map(el => {
          if (userData.activity && userData.activity === el.name) {
            this.userData.activity = el.name
          }
          return el.name
        })

        // Женя просил так
        if (!this.userData.activity) {
          this.activity.push(userData.activity)
          this.userData.activity = userData.activity
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })

      this.originalTimezone = data.data.timezones
      this.timezone = data.data.timezones.map(el => {
        if (userData.timezone_id && userData.timezone_id === el.id) {
          this.timezoneValue = el.name
        }
        return el.name
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', {err})
    })

    fetch(window.host + 'users/offices', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.tableData.data = data.data.map(el => {
        return {
          id: el.id,
          branchName: el.name,
          branchAddress: el.address
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', {err})
    })
  }
}
</script>

<style lang="scss" src="./settingsProfile.scss" scoped/>
<style lang="scss" src="../../../components/textInput/textInput.scss" scoped/>
