<template>
  <div class="password-wrapper">
    <div class="password-container">
      <div class="password-changer">
        <div class="x-input-item">
          <div class="input-name">
              <span class="note-wrapper">
              Новый пароль
              <note note-position="bottom-to-right">
                Пaроль должен содержать не менее 8 символов. Минимум 1 заглавная буква. Минимум 1 цифра. Минимум 1 спецсимвол (! @ $ % и т.п.)
              </note>
            </span>
          </div>
          <div class="input-content" :class="errors.password && 'error-wrap'">
            <textInput
                :type="isShowPassword ? 'text' : 'password'"
                autocomplete="new-password"
                placeholder="••••••••••••••••"
                v-bind:defaultValue="password"
                @onChange="onChangePassword"
            >
              <template v-slot:after>
                <eye :onClick="showPassword"/>
              </template>
            </textInput>
          </div>
        </div>
        <div v-if="!!errors.password" class="error error-input">
          {{ errors.password }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Подтверждение</div>
          <div class="input-content" :class="errors.c_password && 'error-wrap'">
            <textInput
                :type="isShowC_password ? 'text' : 'password'"
                autocomplete="new-password"
                placeholder="••••••••••••••••"
                v-bind:defaultValue="c_password"
                @onChange="onChangeRepeatPassword"
            >
              <template v-slot:after>
                <eye :onClick="showRepeatPassword"/>
              </template>
            </textInput>
          </div>
        </div>
        <div v-if="!!errors.c_password" class="error error-input">
          {{ errors.c_password }}
        </div>
      </div>
      <div class="buttons-container">
        <router-link class="button-back" to="/admin/settings/profile">
          Назад
        </router-link>
        <xButton @onClick="onPasswordSave">
          Сменить пароль
        </xButton>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import eye from '@/components/eye'
import note from '@/components/note'

export default {
  name: 'changePassword',
  components: {
    textInput,
    xButton,
    eye,
    note
  },
  data () {
    return {
      isShowPassword: false,
      isShowC_password: false,
      password: '',
      c_password: '',
      errors: {
        password: '',
        c_password: ''
      }
    }
  },
  computed: {
    isPasswordValid () {
      const upSymbol = /([A-Z]|[А-ЯЁ])/gmu.test(this.password)
      const numberExist = /[0-9]/gmu.test(this.password)
      const noSymbol = /\W/gmu.test(this.password)

      return upSymbol && numberExist && noSymbol
    }
  },
  methods: {
    showPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    showRepeatPassword () {
      this.isShowC_password = !this.isShowC_password
    },
    onChangePassword (val) {
      this.password = val
      if (val.length >= 8) {
        this.errors.password = ''
      }
    },
    onChangeRepeatPassword (val) {
      this.c_password = val
      if (val.length >= 8 && val === this.password) {
        this.errors.c_password = ''
      }
    },
    onPasswordSave () {
      if (this.password.length < 8) {
        this.errors.password = 'Минимальная длинна пароля 8 символов'
      } else if (!this.isPasswordValid) {
        this.errors.password = 'Пaроль должен содержать: минимум 1 заглавную букву, минимум 1 цифру, минимум 1 спецсимвол (! @ $ % и т.п.)'
      }

      if (this.c_password.length < 8) {
        this.errors.c_password = 'Минимальная длинна пароля 8 символов'
      } else {
        if (this.c_password !== this.password) {
          this.errors.c_password = 'Пароли не совпадают'
        }
      }

      if (Object.values(this.errors).join('').length) {
        return
      }

      fetch(window.host + 'users/password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          password: this.password,
          c_password: this.c_password
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        await this.$router.push('/admin/settings/profile')
        this.$store.commit('animateStore/animateNotification')
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }
  }
}
</script>

<style lang="scss" src="./changePassword.scss" scoped />
