<template>
  <xTable
    v-if="tableData.data.length"
    v-bind="tableData"
    @onPageChange="requestData"
  >
    <template v-slot="{ records }" >
      <td>
        <button v-if="isShowMoreBtn(records.id)" @click="setMoreActionPopupData(records.id)" class="msg-table-edit">
          <span class="link">Подробнее</span>
        </button>
      </td>
    </template>
  </xTable>
  <div v-else class="empty-list">
    Список бонусов этого клиента пока пуст
  </div>
  <pop-up :open="popupData" @close="popupData = !popupData" sizer="pop-verysmall">
    <div class="x-pop-desc-black">
      {{ popupMoreData.date }}
    </div>
    <div class="more-keyinfo">
      <h2 class="x-pop-title">
        Подробнее об операции {{ popupMoreData.id }}
      </h2>
    </div>
    <div class="x-pop-spacer"></div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Комментарий:
      </div>
      <div class="x-pop-item__text">
        {{ popupMoreData.comment }}
      </div>
    </div>
    <div class="x-pop-spacer"></div>
    <div class="x-pop-footer">
      <xButton additionalClass="x-button-mr" @click="popupData = !popupData">
        Закрыть
      </xButton>
    </div>
  </pop-up>
  <pop-up :open="popUpOperation" @close="popUpOperation = !popUpOperation" sizer="pop-verysmall">
    <div class="x-pop-desc-black">
      {{ popUpOperationData.date }}
    </div>
    <div class="more-keyinfo">
      <h2 class="x-pop-title">
        Операция {{ popUpOperationData.number }}
      </h2>
      <p v-if="popUpOperationData.refund_id" class="x-pop-desc">
        Возврат по <span class="return red" @click="setOperationPopupDataFromRequest(popUpOperationData.refund_id)">Операции {{ popUpOperationData.number }}</span>
      </p>
    </div>
    <div class="x-pop-spacer"></div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        {{ popUpOperationData.refund_id ? 'Сумма возврата' : 'Сумма покупки' }}
      </div>
      <div v-if="!popUpOperationData.refund_id" class="x-pop-item__text">
        {{ getMoreDataSum }} RUB
      </div>
      <p class="x-pop-desc">
        <span :class="popUpOperationData.refund_id && 'red'">{{ getMoreDataBonuses }}</span> - бонусами
        <br>
        <span :class="popUpOperationData.refund_id && 'red'">{{ getMoreDataRub }}</span> - деньгами
      </p>
    </div>
    <div v-if="popUpOperationData.refunds.length" class="x-pop-spacer"></div>
    <div v-if="popUpOperationData.refunds.length" class="x-pop-item">
      <div class="x-pop-item__desc">
        Возврат по операции
      </div>
      <p v-for="refund in popUpOperationData.refunds"
         :key="refund.id"
         class="x-pop-desc"
      >
        <span>{{ refund.created_at }}</span>
        <br>
        <span class="red">{{ getFormattedBonuses(refund.bonuses) }}</span> - бонусами,
        <span class="red">{{ getFormattedRub(refund.roubles) }}</span> - деньгами
        <span class="return" @click="setOperationPopupDataFromRequest(refund.id)">Детали</span>
      </p>
    </div>
    <div class="x-pop-spacer"></div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Клиент
      </div>
      <router-link :to="'/admin/customer/' + popUpOperationData.clientId" class="x-pop-item__text link">
        {{ popUpOperationData.clientName }}
      </router-link>
    </div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Кассир
      </div>
      <router-link :to="'/admin/team/' + popUpOperationData.cashierId" class="x-pop-item__text link">
        {{ popUpOperationData.cashierName }}
      </router-link>
    </div>
    <div class="x-pop-footer">
      <xButton
        v-if="!(popUpOperationData.sum_refunds >= popUpOperationData.sum || popUpOperationData.refund_id)"
        @click="goRefund"
      >
        Возврат по операции
      </xButton>
      <button
        v-if="!popUpOperationData.refund_id"
        @click="deleteMoreAction"
        class="link x-pop-back"
        :class="(popUpOperationData.sum_refunds >= popUpOperationData.sum || popUpOperationData.refund_id) && 'ml-auto'"
      >
        Удалить
      </button>

      <xButton v-if="popUpOperationData.refund_id" @click="popUpMore = !popUpMore">Закрыть</xButton>
    </div>
  </pop-up>
  <transition name="RefundPop">
    <pop-up :open="popUpRefund" @close="popUpRefund = !popUpRefund">
      <div class="refund-pop">
        <h2 class="x-pop-title">
          Возврат по операции
        </h2>
        <div class="refund-info">
          <div class="refund-item">
            <div class="x-pop-desc">Сумма покупки</div>
            <text-input :default-value="getMoreDataSum()" :disabled="true" />
          </div>
          <div class="refund-item">
            <div class="x-pop-desc">Деньги</div>
            <text-input :default-value="getMoreDataRub()" :disabled="true" />
          </div>
          <div class="refund-item">
            <div class="x-pop-desc">Бонусы</div>
            <text-input :default-value="getMoreDataBonuses()" :disabled="true" />
          </div>
        </div>
        <div class="x-pop-spacer"></div>
        <div class="x-pop-desc-black">
          Введите сумму, на которую нужно сделать возврат. <br> Деньги и бонусы к возврату будут рассчитаны пропорционально
        </div>
        <div class="x-pop-desc">Сумма к возврату</div>
        <div class="input-wrapper">
          <div class="input-container" >
            <numberFormat
              class="input"
              v-model:value="returnSum"
              :options="numberFormat"
              @blur="e => onChangeReturnSum(e.target.value)"
            ></numberFormat>
          </div>
        </div>
        <div class="x-pop-footer">
          <xButton @click="returnAction">Сделать возврат</xButton>
          <button @click="closeReturnAction" class="link x-pop-back">Отмена</button>
        </div>
      </div>
    </pop-up>
  </transition>
</template>

<script>
import xTable from '@/components/xTable'
import popUp from '@/components/popUp'
import xButton from '@/components/xButton'
import numberFormat from '@/components/numberFormat'
import textInput from '@/components/textInput'

export default {
  name: 'customerBonuses',
  components: {
    xTable,
    popUp,
    xButton,
    numberFormat,
    textInput
  },
  emits: ['titleName'],
  data () {
    return {
      numberFormat: {
        decimal: '',
        thousand: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      returnSum: '',
      popupData: false,
      popupMoreData: {
        id: 0,
        date: '23.07.2022 09:36:31',
        operation: 'Списание бонусов за операцию',
        bonusDeleteNumber: '50',
        bonusAddNumber: '50',
        bonusTotal: '300',
        comment: 'test comment message'
      },
      popUpOperation: false,
      popUpRefund: false,
      popUpOperationData: {
        id: 0,
        number: '0',
        date: '00.00.0000 00:00',
        sum: '0',
        rub: '0',
        bonuses: '0',
        currency: 'RUB',
        name: '',
        clientName: '',
        clientId: 3,
        cashierName: '',
        cashierId: 3,
        refund_id: 0,
        refunds: [],
        sum_refunds: 0
      },
      originalData: [],
      tableData: {
        dateTwoStroke: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Дата', value: 'date', id: 0 },
          { name: 'Операции', value: 'operation', id: 1 },
          { name: 'Списано', value: 'bonusDeleteNumber', id: 2 },
          { name: 'Начислено', value: 'bonusAddNumber', id: 3 },
          { name: 'Остаток', value: 'bonusTotal', id: 4 },
          { name: '', value: 'empty', id: 5 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Дата', 'Операции', 'Списано', 'Начислено', 'Остаток', ''],
        namesForHeaderX: []
      }
    }
  },
  methods: {
    closeReturnAction () {
      this.popUpRefund = false
      this.returnSum = ''
    },
    returnAction () {
      this.popUpRefund = false
      if (this.returnSum) {
        fetch(`${window.host}purchases/${this.popUpMoreData.id}/refund`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            sum: this.returnSum
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          this.requestClients()
          this.$store.commit('animateStore/animateNotification')
          this.returnSum = ''
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    },
    onChangeReturnSum (val) {
      const toInt = parseInt(val.replace(' ', ''))
      if (toInt > this.popUpOperationData.sum) {
        this.returnSum = this.popUpOperationData.sum
      }
    },
    getMoreDataSum () {
      return new Intl.NumberFormat('ru-RU').format(this.popUpOperationData.sum)
    },
    getMoreDataRub () {
      return new Intl.NumberFormat('ru-RU').format(this.popUpOperationData.rub)
    },
    getMoreDataBonuses () {
      return new Intl.NumberFormat('ru-RU').format(this.popUpOperationData.bonuses)
    },
    getFormattedRub (rub) {
      return new Intl.NumberFormat('ru-RU').format(rub)
    },
    getFormattedBonuses (bonuses) {
      return new Intl.NumberFormat('ru-RU').format(bonuses)
    },
    goRefund () {
      this.popUpOperation = false
      this.popUpRefund = true
    },
    deleteMoreAction () {
      this.popUpOperation = false

      this.$store.commit(
        'popupStore/show',
        {
          title: 'Вы удаляете операцию',
          text: 'Подтвердите удаление',
          buttonText: 'Отмена',
          rollBackButtonText: 'Удалить',
          useFullWindow: false,
          callback: () => {},
          errorCallback: () => {
            fetch(`${window.host}purchases/${this.popUpOperationData.id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access_token')
              }
            }).then(async response => {
              const data = await response.json()

              if (!response.ok || !data.success) {
                const error = (data && data.message) || response.status
                return Promise.reject(error)
              }

              this.requestData()
              this.$store.commit('animateStore/animateNotification')
            }).catch(err => {
              this.$store.commit('techStore/checkError', { err })
            })
          }
        }
      )
    },
    setOperationPopupDataFromRequest (id) {
      fetch(`${window.host}purchases/${id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.popUpOperation = false
        setTimeout(() => {
          this.popUpOperation = true
          const moreData = data.data

          this.popUpOperationData.id = moreData.id
          this.popUpOperationData.date = moreData.created_at
          this.popUpOperationData.number = moreData.number
          this.popUpOperationData.sum = moreData.sum
          this.popUpOperationData.rub = moreData.roubles
          this.popUpOperationData.bonuses = moreData.bonuses
          this.popUpOperationData.name = this.$store.state.techStore.companyName || ''
          this.popUpOperationData.clientName = moreData.client.name
          this.popUpOperationData.clientId = moreData.client.id
          this.popUpOperationData.cashierName = moreData.cashier ? moreData.cashier.name : '─'
          this.popUpOperationData.cashierId = moreData.cashier ? moreData.cashier.id : '─'
          this.popUpOperationData.refund_id = moreData.refund_id
          this.popUpOperationData.sum_refunds = moreData.sum_refunds
          this.popUpOperationData.refunds = moreData.refunds.map(el => ({
            id: el.id,
            created_at: el.created_at,
            bonuses: el.bonuses,
            roubles: el.roubles
          }))
        }, 500)
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    setMoreActionPopupData (id) {
      const findEl = this.originalData.find(el => el.id === id)
      if (findEl) {
        this.popupMoreData = findEl
        // eslint-disable-next-line dot-notation
        if (typeof this.popupMoreData['purchase_id'] !== 'undefined' && this.popupMoreData['purchase_id']) {
          this.setOperationPopupDataFromRequest(this.popupMoreData.purchase_id)
        }
        this.popupData = true
      }
    },
    isShowMoreBtn (id) {
      const findEl = this.originalData.find(el => el.id === id)
      if (findEl) {
        // eslint-disable-next-line dot-notation
        if (typeof this.popupMoreData['purchase_id'] !== 'undefined' && this.popupMoreData['purchase_id']) {
          return this.popupMoreData.purchase_id
        }
        return findEl.comment
      }
      return false
    },
    requestData (page = false) {
      const pagePart = page ? `?page=${page}` : ''
      const id = this.$router.currentRoute.value.params.id

      fetch(`${window.host}clients/${id}/bonuses/${pagePart}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!data.success) {
          this.$router.push({
            name: 'error'
          })
        }

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        const bonuses = data.data.bonuses
        this.originalData = bonuses
        this.tableData.data = bonuses.map(el => ({
          id: el.id,
          date: el.created_at || '─',
          operation: el.operation || '─',
          bonusDeleteNumber: el.spent || '0',
          bonusAddNumber: el.added || '0',
          bonusTotal: el.balance || '0'
        }))

        const paginate = data.data.paginate
        this.tableData.totalPagesX = paginate.last_page
        this.tableData.pageSizeX = paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  created () {
    this.requestData()

    const id = this.$router.currentRoute.value.params.id

    fetch(`${window.host}clients/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const userData = data.data

      this.$emit('titleName', userData.name)
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./customerBonuses.scss" scoped />
