<template>
  <div class="spinner" />
</template>

<script>
export default {
  name: 'spinner'
}
</script>

<style lang="scss" src="./spinner.scss" scoped />
