<template>
  <publicArea>
    <div class="welcome-wrapper">
      <div class="welcome-logo-container">
        <img :src="logo" alt="Логотип компании" onerror="this.style.display='none'">
      </div>
      <h2 class="title">
        Зарегистрируйтесь в программе лояльности «{{ companyName }}» — получайте бонусы и выгодные предложения
      </h2>
      <form class="welcome-form">
        <div class="x-input-item">
          <div class="input-name">Ваш номер телефона</div>
          <div class="input-content phone-option-container" :class="errors.phone ? 'error-wrap' : ''">
            <div class="input-wrapper">
              <div class="input-container">
                <input
                  class="input ml-50"
                  type="text"
                  v-mask="phoneMask"
                  :placeholder="selectedPhoneTemplate ? selectedPhoneTemplate.code : ''"
                  :value="phone"
                  @input="event => onChangePhone(event.target.value)"
                >
              </div>
              <div v-if="errors.phone" class="error error-input">
                {{ errors.phone }}
              </div>
            </div>
            <div class="phone-options">
              <div class="phone-select">
                <div class="option selected" @click="openPhones">
                  <div v-if="selectedPhoneTemplate" class="flag">
                    <img :src="selectedPhoneTemplate.icon" :alt="selectedPhoneTemplate.country">
                  </div>
                  <div class="arrow-down" :class="isPhoneTemplatesOpen ? 'active' : 'disactive'">
                    <icon name="arrow"/>
                  </div>
                </div>
                <div class="options" :class="isPhoneTemplatesOpen ? 'show' : ''">
                  <div
                    v-for="template in phoneTemplates"
                    :key="template"
                    class="option"
                    @click="selectedPhones(template.id)"
                  >
                    <div class="flag">
                      <img :src="template.icon" :alt="template.country">
                    </div>
                    <div class="county">{{ template.country }}</div>
                    <div class="code">{{ template.code }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Ваше имя <span>*</span></div>
          <div class="input-content" :class="errors.name ? 'error-wrap' : ''">
            <div class="input-wrapper">
              <div class="input-container">
                <input class="input" v-model="name" placeholder="Имя">
              </div>
              <div v-if="errors.name" class="error">
                {{ errors.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Ваша фамилия <span>*</span></div>
          <div class="input-content" :class="errors.secondName ? 'error-wrap' : ''">
            <div class="input-wrapper">
              <div class="input-container">
                <input class="input" v-model="secondName" placeholder="Фамилия">
              </div>
              <div v-if="errors.secondName" class="error">
                {{ errors.secondName }}
              </div>
            </div>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Пол <span>*</span></div>
          <div class="input-content" :class="!errors.gender ? 'error-wrap' : ''">
            <div class="input-wrapper">
              <Multiselect
                v-model="gender"
                :options="genderSelect"
                placeholder=""
                :canClear="false"
              />
              <div v-if="!errors.gender" class="error">
                {{ errors.gender }}
              </div>
            </div>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Дата рождения (будем дарить подарки)</div>
          <div class="input-content" :class="errors.date ? 'error-wrap' : ''">
            <div class="input-wrapper">
              <div class="input-container">
                <input
                  type="date"
                  class="input input-date"
                  v-model="userDate"
                  onClick="event.preventDefault()"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  placeholder="__.__.____"
                >
<!--                <icon-->
<!--                  name="date"-->
<!--                  class="replacement-date"-->
<!--                  @click="triggerDateClick"-->
<!--                />-->
<!--                <input-->
<!--                  class="input-date hidden-date"-->
<!--                  type="date"-->
<!--                  ref="datePicker"-->
<!--                  v-model="date"-->
<!--                >-->
              </div>
              <div v-if="errors.date" class="error error-input">
                {{ errors.date }}
              </div>
            </div>
          </div>
        </div>
        <div class="x-image-radio">
          <div class="image-radio-title">
            Выберите удобный канал связи
          </div>
          <div class="image-radio-wrap">
            <div v-if="integrations.telegram" class="radio-wrap">
              <div class="image-radio" @click="registerAction('telegram')">
                <icon name="telegram" />
              </div>
            </div>
            <div v-if="integrations.vk" class="radio-wrap">
              <div class="image-radio" @click="registerAction('vk')">
                <icon name="vk" />
              </div>
            </div>
            <div v-if="integrations.viber" class="radio-wrap">
              <div class="image-radio" @click="registerAction('viber')">
                <icon name="viber" />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="contacts">
        <div class="contacts-container">
          <div class="contacts-socials"></div>
          <div class="contacts-text">
            При переходе по ссылке канала связи, вы даете согласие на обработку ваших персональных данных и принимаете условия
            <router-link to="/admin" class="link">Пользовательского соглашения</router-link>
          </div>
        </div>
      </div>
    </div>
  </publicArea>
  <errorPopup additionalClass="l-un"/>
</template>

<script>
import publicArea from '@/components/publicArea'
import errorPopup from '@/components/errorPopup'
import moment from 'moment'

export default {
  name: 'welcome',
  components: {
    publicArea,
    errorPopup
  },
  data: () => ({
    genderSelect: [
      'Мужской',
      'Женский'
    ],
    gender: '',
    dateMask: '##.##.####',
    companyName: '',
    logo: '',
    integrations: {
      telegram: false,
      viber: false,
      vk: false
    },
    name: '',
    secondName: '',
    phone: '',
    userDate: '',
    date: '',
    phoneTemplatesSelectedId: 1,
    phoneTemplates: [],
    isPhoneTemplatesOpen: false,
    errors: {
      name: '',
      secondName: '',
      phone: '',
      date: '',
      gender: ''
    }
  }),
  computed: {
    phoneMask () {
      const phoneTemplate = this.selectedPhoneTemplate
      if (!phoneTemplate) {
        return ''
      }
      const endMask = '###-##-##'
      const hooks = '#'.repeat(5 - phoneTemplate.code.length)

      return phoneTemplate.code + ' (' + hooks + ') ' + endMask
    },
    selectedPhoneTemplate () {
      return this.phoneTemplates.find(el => el.id === this.phoneTemplatesSelectedId)
    },
    isErrorExist () {
      return Object.values(this.errors).join('').length
    },
    correctPhone () {
      const code = this.selectedPhoneTemplate ? this.selectedPhoneTemplate.code : ''
      return `${code}${this.phone}`.replace(/\D+/gmu, '')
    }
  },
  watch: {
    name: function () {
      this.errors.name = ''
    },
    gender: function () {
      this.errors.gender = ''
    },
    secondName: function () {
      this.errors.secondName = ''
    },
    phone: function () {
      this.errors.phone = ''
    },
    date: function () {
      // const splitDate = this.date.split('-')
      // const year = splitDate[0]
      // const month = splitDate[1]
      // const day = splitDate[2]

      this.errors.date = ''
    },
    userDate: function () {
      this.date = this.userDate;

      this.errors.date = ''
    }
  },
  methods: {
    triggerDateClick () {
      const datePicker = this.$refs.datePicker
      datePicker.click()
    },
    onChangePhone (val) {
      const correctPhone = val.replace(this.selectedPhoneTemplate.code, '')
      if (this.phone !== correctPhone) {
        this.phone = correctPhone
      }
    },
    selectedPhones (id) {
      this.isPhoneTemplatesOpen = false
      this.phoneTemplatesSelectedId = id
      this.phone = this.phoneMask
    },
    openPhones () {
      this.isPhoneTemplatesOpen = !this.isPhoneTemplatesOpen
    },
    validate () {
      if (!this.name.length) {
        this.errors.name = 'Заполните поле'
      }
      if (!this.secondName.length) {
        this.errors.secondName = 'Заполните поле'
      }
      if (!this.phone.length) {
        this.errors.phone = 'Заполните поле'
      } else if (this.correctPhone.length < 11) {
        this.errors.phone = 'Укажите корректный телефон'
      }
      if (this.date.length) {
        const userDate = moment(this.date, 'YYYY-MM-DD')
        const diffDate = (moment()).subtract(5, 'years')

        if (userDate.diff(diffDate) >= 0) {
          this.errors.date = 'Укажите верный год рождения'
        }
      }
      if (!this.gender.length) {
        this.errors.gender = 'Выберите пол'
      }
    },
    async registerAction (messenger) {
      this.validate()

      if (this.isErrorExist) {
        this.$store.commit(
          'popupStore/show',
          { text: 'Введены неверные данные', useFullWindow: true }
        )
        return
      }

      // await this.$recaptchaLoaded()
      // const token = await this.$recaptcha('welcome')

      const id = typeof this.$router.currentRoute.value.params.id !== 'undefined' ? this.$router.currentRoute.value.params.id : false

      const genderValue = this.gender === 'Мужской' ? 'male' : 'female'

      fetch(`${window.host}landing/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          name: this.name,
          secondName: this.secondName,
          phone: this.correctPhone,
          date: this.date,
          gender: genderValue,
          messenger,
          // token,
          referral: this.$route.query.referral
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status

          this.$store.commit(
            'popupStore/show',
            { text: data.message, buttonText: 'Закрыть', useFullWindow: true }
          )

          return Promise.reject(error)
        }

        if (data.data) {
          window.location.href = data.data
        } else {
          this.$store.commit(
            'popupStore/show',
            { text: 'Ошибка мессенджера', buttonText: 'Закрыть', useFullWindow: true }
          )
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    show404 () {
      this.$router.push({
        name: 'error'
      })
    }
  },
  created () {
    const id = typeof this.$router.currentRoute.value.params.id !== 'undefined' ? this.$router.currentRoute.value.params.id : false

    if (!id) {
      this.show404()
    }

    fetch(`${window.host}landing/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      if (!data.data) {
        this.show404()
        return
      }

      const companyData = data.data

      const integrationsArr = Object.values(companyData.integrations)
      const disabledIntegrationsArr = integrationsArr.filter(el => el === false)

      if (integrationsArr.length === disabledIntegrationsArr.length) {
        this.show404()
        return
      }

      this.integrations = companyData.integrations
      this.companyName = companyData.company.toUpperCase()

      if (companyData.logotype) {
        this.logo = `${window.domen}${companyData.logotype}`
      }
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    fetch(window.host + 'phones/public/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.phoneTemplates = data.data.filter(el => el.active === 1).map(el => {
        return {
          id: el.id,
          icon: window.domen + el.icon,
          country: el.country,
          code: el.code
        }
      })
      if (this.phoneTemplates.length) {
        this.phoneTemplatesSelectedId = this.phoneTemplates[0].id
      }

      this.phone = this.phoneMask
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./welcome.scss" scoped />
<style lang="scss" src="../../components/textInput/textInput.scss" scoped/>
