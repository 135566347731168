<template>
  <adminNav>
    <router-link to="/admin/messages/automation" :class="{'router-link-active': this.$route.path.includes('/automation')}">
      Автоматизация
    </router-link>
    <router-link to="/admin/messages/news" :class="{'router-link-active': this.$route.path.includes('/news')}">
      Новости / Акции
    </router-link>
    <router-link to="/admin/messages/chat">
      Чат с клиентами
      <div class="notify__item">
        <notifyNumber :number="this.$store.state.techStore.messagesCount" />
      </div>
    </router-link>
  </adminNav>
  <router-view />
</template>

<script>
import adminNav from '@/components/adminNav/adminNav'
import notifyNumber from '@/components/notifyNumber'

export default {
  name: 'messages',
  components: {
    adminNav,
    notifyNumber
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Сообщения')
  }
}
</script>

<style lang="scss" src="./messages.scss" scoped />
