<template>
    <svg preserveAspectRatio="none"  viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="96" height="96" rx="48" fill="#7360F2"/>
      <path d="M69.356 29.1424C68.0459 27.9335 62.7515 24.0901 50.9586 24.0378C50.9586 24.0378 37.0517 23.1992 30.2723 29.4177C26.4985 33.1923 25.171 38.7153 25.031 45.5627C24.8909 52.4101 24.7097 65.2426 37.0794 68.7221H37.0913L37.0834 74.0315C37.0834 74.0315 37.0042 76.1811 38.4196 76.6194C40.1317 77.151 41.1364 75.5173 42.7709 73.7562C43.6681 72.7894 44.9071 71.3693 45.8406 70.2838C54.3004 70.9959 60.8068 69.3685 61.5458 69.128C63.2539 68.5741 72.9194 67.336 74.4914 54.5043C76.114 41.2786 73.7066 32.913 69.356 29.1424Z" fill="white"/>
      <path d="M66.6047 31.8127C65.4971 30.8064 60.7162 27.8024 50.7405 27.7589C50.7405 27.7589 38.9279 26.9827 33.1936 32.1767C30.0021 35.3278 28.9269 40.0138 28.809 45.7323C28.6911 51.4507 28.0804 62.3749 38.5402 65.2808C38.5402 65.2808 38.4991 73.3909 38.4943 74.1037C38.4943 74.6022 38.5734 74.9432 38.8582 75.0136C39.0631 75.0634 39.3701 74.9574 39.6312 74.6971C41.3037 73.0175 46.6582 66.5577 46.6582 66.5577C53.8427 67.0269 59.5587 65.6084 60.1735 65.4074C61.6228 64.9414 69.4624 64.2682 70.7891 53.5512C72.1602 42.5044 70.2852 34.9639 66.6047 31.8127Z" fill="#7360F2"/>
      <path d="M55.813 45.2637C55.6832 42.6481 54.3546 41.2744 51.8271 41.1426" stroke="white" stroke-width="1.33388" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M59.2354 46.4099C59.2881 43.9732 58.5663 41.9373 57.07 40.3023C55.5668 38.6614 53.4861 37.7579 50.8159 37.5625" stroke="white" stroke-width="1.33388" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M62.738 47.7819C62.7058 43.5535 61.4423 40.2233 58.9475 37.7913C56.4528 35.3593 53.3502 34.1303 49.6396 34.1045" stroke="white" stroke-width="1.33388" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M51.9589 54.197C51.9589 54.197 52.8964 54.2762 53.4012 53.6543L54.3854 52.4162C54.8601 51.8022 56.0057 51.4106 57.1275 52.0356C57.9682 52.5151 58.7844 53.0363 59.573 53.5973C60.3174 54.1448 61.842 55.417 61.8467 55.417C62.573 56.0301 62.7407 56.9305 62.2463 57.8799C62.2463 57.8854 62.2423 57.8949 62.2423 57.8996C61.6977 58.8437 61.0063 59.6951 60.194 60.4218C60.1845 60.4266 60.1845 60.4313 60.1758 60.4361C59.4701 61.0257 58.7768 61.3609 58.0959 61.4416C57.9956 61.4592 57.8937 61.4655 57.7921 61.4606C57.4918 61.4635 57.1931 61.4175 56.9076 61.3245L56.8854 61.2921C55.8364 60.9962 54.0847 60.2557 51.1678 58.6465C49.4798 57.7262 47.8727 56.6647 46.3639 55.4732C45.6076 54.8763 44.8862 54.2365 44.2033 53.557L44.1305 53.4842L44.0577 53.4114L43.9849 53.3386C43.9604 53.3149 43.9366 53.2904 43.9121 53.2659C43.2326 52.5829 42.5928 51.8615 41.9959 51.1052C40.8046 49.5965 39.7431 47.9898 38.8226 46.3021C37.2134 43.3844 36.4729 41.6343 36.177 40.5837L36.1446 40.5615C36.0518 40.276 36.0061 39.9773 36.0093 39.677C36.0038 39.5754 36.0099 39.4735 36.0275 39.3732C36.1124 38.6934 36.4481 37.9995 37.0346 37.2917C37.0394 37.283 37.0441 37.283 37.0489 37.2735C37.7754 36.4612 38.6268 35.7701 39.5711 35.226C39.5758 35.226 39.5853 35.2213 39.5908 35.2213C40.5402 34.7268 41.4406 34.8945 42.0529 35.6168C42.0577 35.6216 43.3275 37.1461 43.8726 37.8906C44.4337 38.68 44.9549 39.4969 45.4343 40.3384C46.0593 41.4595 45.6677 42.6075 45.0537 43.0806L43.8156 44.0648C43.1906 44.5695 43.2729 45.507 43.2729 45.507C43.2729 45.507 45.1068 52.4478 51.9589 54.197Z" fill="white"/>
    </svg>
</template>
