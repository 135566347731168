<template>
  <adminNav>
    <router-link to="/admin/settings/profile" @click="e => onClickLink(e, 'profile')">Профиль компании</router-link>
    <router-link to="/admin/settings/bonuses" @click="e => onClickLink(e, 'bonuses')">Бонусная программа</router-link>
    <router-link to="/admin/settings/referrals" @click="e => onClickLink(e, 'referrals')">Реферальная программа</router-link>
    <router-link to="/admin/settings/service" @click="e => onClickLink(e, 'service')">Сервисные сообщения</router-link>
    <router-link to="/admin/settings/integrations" @click="e => onClickLink(e, 'integrations')">Интеграции</router-link>
  </adminNav>
  <router-view />
</template>

<script>
import adminNav from '@/components/adminNav'

export default {
  name: 'settings',
  components: {
    adminNav
  },
  emits: ['titleName'],
  methods: {
    onClickLink (e, path) {
      if (this.$store.state.techStore.isFormChanged) {
        e.preventDefault()
        const fullPath = `/admin/settings/${path}`

        this.$store.commit(
          'popupStore/show',
          {
            text: 'Вы не сохранили изменения. Внесенные изменения будут потеряны',
            buttonText: 'Сохранить изменения',
            title: 'Внимание',
            rollBackButtonText: 'Выйти без сохранения',
            useFullWindow: false,
            callback: async () => {
              this.$store.state.techStore.formSaveCallback()
              await this.$router.push(fullPath)
            },
            errorCallback: async () => {
              await this.$router.push(fullPath)
              this.$store.commit(
                'techStore/setFormChanged',
                {
                  isChanged: false,
                  callback: () => {}
                }
              )
            }
          }
        )
      }
    }
  },
  created () {
    this.$emit('titleName', 'Настройки')
  }
}
</script>

<style lang="scss" scoped src="./settings.scss" />
