

export default {
  namespaced: true,
  state () {
    return {
      notificationTitle: false,
      notificationDesc: false,
      isShowNotification: false,
      status: 'success'
    }
  },
  mutations: {
    async animateNotification (state, props) {
      const { text, desc, status } = props || {}

      state.status = null

      if (text) {
        state.notificationTitle = text
      }

      if (desc || desc === '') {
        state.notificationDesc = desc
      }

      if (status === 'error') {
        state.status = 'error'
      } else {
        setTimeout(() => {
          state.isShowNotification = false
          state.status = 'success'
        }, 4000)
        setTimeout(() => {
          state.notificationTitle = false
        }, 5000)
      }

      state.isShowNotification = true
    },
    closeNotification (state) {
      state.isShowNotification = false
      setTimeout(() => {
        state.status = 'success'
        state.notificationTitle = false
        state.notificationDesc = false
      }, 350)

    }
  }
}
