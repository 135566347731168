<template>
  <div class="layout-1">
    <div class="create-header create-block">
      <div class="x-input-item">
        <div class="input-name">Название</div>
        <div class="input-content">
          <textInput :default-value="form.name" @onChange="form.name = $event; textValidation.name = true"
                     id="form-name"
                     placeholder="Назовите автоматизацию"
                     :validate="!textValidation.name"
                     validate-text="Название не может быть пустым"/>
        </div>
      </div>
      <div class="x-input-item x-input-date">
        <div class="input-name">Когда отправлять</div>
        <div class="input-content max-w-180">
          <Multiselect
            v-model="form.send_type"
            :options="inputOptions.timer"
            placeholder="Сразу"
            :canClear="false"
          />
        </div>
        <textInput class="input-hours" @onChange="form.send_hours = $event"
                   v-if="form.send_type === 'Через' || form.send_type === 'За'"
                   :options="{type: 'integer', min: 1, max: 1000}" :default-value="form.send_hours"/>
        <div v-if="form.send_type === 'Сразу'" class="create-conditions-afterword">в момент наступления события</div>
        <div v-if="form.send_type === 'Через'" class="create-conditions-afterword">часов после наступления события</div>
        <div v-if="form.send_type === 'За'" class="create-conditions-afterword">часов до наступления события</div>
      </div>
      <div class="x-input-item x-input-item-noteTop"
           :class="{'x-input-EmptyAfterword': !config_event[form.event_type]?.afterword, 'x-input-wrap' : config_event[form.event_type]?.wrap}">
        <div class="input-name">Событие</div>
        <div class="input-content input-content-event">
          <Multiselect
            v-model="form.event_type"
            :options="Object.keys(config_event)"
            placeholder="Выберите событие"
            id="form-event_type"
            @change="setTemplates"
            :canClear="false"
          />
        </div>
        <div class="create-conditions-event">
          <template v-if="config_event[form.event_type]">
            <textInput class="input-hours" id="form-event_days"
                       v-if="config_event[form.event_type].input?.type === 'integer'"
                       :options="config_event[form.event_type].input || {}" :default-value="form.event_value_1"
                       validate-text="Заполните"
                       @onChange="form.event_value_1 = $event"/>
            <div class="input-content input-multiselect-status"
                 v-if="config_event[form.event_type].input?.type === 'multiselect'">
              <Multiselect
                v-model="form.event_value_multiselect"
                :options="statuses.map(item => item.name)"
                id="form-event_value"
                placeholder="Выберете статус"
                :canClear="false"
              />
            </div>
            <template v-if="config_event[form.event_type].note && config_event[form.event_type].input2">
              <div class="create-conditions-afterword" v-if="config_event[form.event_type].afterword2">
                {{ config_event[form.event_type].afterword2 }}
              </div>
              <textInput class="input-hours" v-if="config_event[form.event_type].input2.type === 'integer'"
                         :options="config_event[form.event_type].input2 || {}" :default-value="form.event_value_2"
                         @onChange="form.event_value_2 = $event"/>
            </template>
            <div v-if="config_event[form.event_type].note" class="create-conditions-afterword">

              <div class="note-wrapper">
                <div>
                  {{ config_event[form.event_type].afterword }}
                </div>
                <note note-position="bottom-to-left"
                      :class="{'empty-afterword-note': !config_event[form.event_type].afterword}">
                  <span v-html="config_event[form.event_type].note"></span>
                </note>
              </div>
            </div>
          </template>
        </div>

      </div>
      <div class="x-input-item">
        <div class="input-name">Что сделать</div>
        <div class="input-content">
          <Multiselect
            v-model="form.action_type"
            :options="inputOptions.send"
            :placeholder="inputOptions.send[0]"
            :canClear="false"
          />
        </div>
      </div>
      <div class="create-conditions" v-if="form.action_type === 'Начислить бонусы и отправить сообщение'">
        <div class="create-conditions-bonuses">
          <div class="x-input-item">
            <div class="input-name">Начислить клиенту</div>
            <div class="input-content">
              <textInput :default-value="form.bonuses" @onChange="form.bonuses = $event"
                         :options="{type: 'integer', min: 1}"/>
            </div>
            <div class="create-conditions-afterword">бонусов</div>
          </div>
        </div>
        <div class="create-conditions-remove">
          <div class="x-input-item">
            <div class="input-name">Сгорают</div>
            <div class="input-content">
              <Multiselect
                v-model="form.bonuses_burn"
                :options="inputOptions.burn_bonuses_action"
                placeholder="Да"
                :canClear="false"
              />
            </div>
          </div>
        </div>
        <div class="create-conditions-term" v-if="form.bonuses_burn === 'Да'">
          <div class="x-input-item">
            <div class="input-name">Через</div>
            <div class="input-content">
              <textInput :options="{type: 'integer', min: 0, max: 1000}" @onChange="form.burn_days = $event"
                         :default-value="form.burn_days"/>
            </div>
            <div class="create-conditions-afterword">дней</div>
          </div>
        </div>
      </div>
    </div>
    <div class="create-message create-block">
      <div class="create-message-send">
        <div class="note-wrapper">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <note note-position="bottom-to-right">
            Вы можете использовать переменные из списка ниже (автополя). Для каждого клиента переменная принимает
            значение, актуальное для этого клиента. <br><br> Например, переменная @Имя_клиента позволяет к каждому
            клиенту обратиться по имени – для каждого будет подставлено его имя. <br><br> Переменные позволяют
            конструировать сообщения, которые в точности соответствую индивидуальной ситуации каждого конкретного
            клиента.
          </note>
        </div>
        <div class="create-message-body">
          <div class="text-wrapper" contenteditable='false'>
            <div class="input-wrapper">
              <div class="input-container">
                <div class="input-clicker input-big" id="form-text" @click="deleteText" ref="superText1"
                     contenteditable="true"
                     v-html="isOnlyMessageSet ? config_event[form.event_type]?.template_only_message : config_event[form.event_type]?.template_default">
                </div>
                <template v-if="!textValidation.superText1">
                  <div class="validate-icon">
                    <icon name="validate"/>
                  </div>
                  <div class="error">
                    Текст не может быть пустым
                  </div>
                </template>
              </div>
            </div>
            <div class="clicker-tech">
              <input
                v-for="autofield in config_event[form.event_type]?.autofields" :key="autofield"
                @click="this.$refs.superText1.focus(); insertText(`<span contenteditable='false' class='link clicker-link'>${autofield}</span>`)"
                class="link clicker-tech-link" type="button" :value="autofield">
            </div>
          </div>
        </div>
        <div class="create-message-button">
          <x-button :loading="loading.superText1" color="outline-grey" class="arrow-hover" size="extra-small"
                    @click="fetchTest('superText1')">
            Отправить тест
            <x-arrow/>
          </x-button>
        </div>
      </div>
    </div>
    <template v-if="!isOnlyMessageSet">
      <div class="create-message-reminder create-block">
        <div class="x-input-item">
          <div class="input-name">Сообщить о скором сгорании бонусов</div>
          <div class="input-content">
            <Multiselect
              v-model="form.notify_before_bonuses_burn"
              :options="inputOptions.reminder"
              placeholder="Да"
              :canClear="false"
            />
          </div>
          <template v-if="form.notify_before_bonuses_burn === 'Да'">
            <div class="create-conditions-afterword">отправить сообщение за</div>
            <div class="input-content">
              <textInput :options="{type: 'integer', min: 1, max: 30}" @onChange="form.nbbb_days = $event"
                         :default-value="form.nbbb_days"/>
            </div>
            <div class="create-conditions-afterword">дней</div>
          </template>
        </div>
        <div v-show="form.notify_before_bonuses_burn === 'Да'" class="create-message-send">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" id="form-text-2" ref="superText2"
                       contenteditable="true"></div>
                  <template v-if="!textValidation.superText2">
                    <div class="validate-icon">
                      <icon name="validate"/>
                    </div>
                    <div class="error">
                      Текст не может быть пустым
                    </div>
                  </template>
                </div>
              </div>
              <div class="clicker-tech">
                <input
                  v-for="autofield in config_event[form.event_type]?.autofields" :key="autofield"
                  @click="this.$refs.superText2.focus(); insertText(`<span contenteditable='false' class='link clicker-link'>${autofield}</span>`)"
                  class="link clicker-tech-link" type="button" :value="autofield">
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button :loading="loading.superText2" color="outline-grey" class="arrow-hover" size="extra-small"
                      @click="fetchTest('superText2')">
              Отправить тест
              <x-arrow/>
            </x-button>
          </div>
        </div>
      </div>
      <div class="create-message-notify create-block">
        <div class="x-input-item">
          <div class="input-name">Сообщить о факте сгорании бонусов</div>
          <div class="input-content">
            <Multiselect
              v-model="form.notify_after_bonuses_burn"
              :options="inputOptions.notify"
              placeholder="Да"
              :canClear="false"
            />
          </div>
        </div>
        <div v-show="form.notify_after_bonuses_burn === 'Да'" class="create-message-send">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" id="form-text-3" ref="superText3"
                       contenteditable="true"></div>
                  <template v-if="!textValidation.superText3">
                    <div class="validate-icon">
                      <icon name="validate"/>
                    </div>
                    <div class="error">
                      Текст не может быть пустым
                    </div>
                  </template>
                </div>
              </div>
              <div class="clicker-tech">
                <input
                  v-for="autofield in config_event[form.event_type]?.autofields" :key="autofield"
                  @click="this.$refs.superText3.focus(); insertText(`<span contenteditable='false' class='link clicker-link'>${autofield}</span>`)"
                  class="link clicker-tech-link" type="button" :value="autofield">
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button :loading="loading.superText3" color="outline-grey" class="arrow-hover" size="extra-small"
                      @click="fetchTest('superText3')">
              Отправить тест
              <x-arrow/>
            </x-button>
          </div>
        </div>
      </div>
    </template>

    <div class="create-message-footer">
      <div class="create-message-footer__save--wrapper">
        <div class="create-message-footer__save">
          <xButton @click="saveAutomation" :loading="loading.save">Сохранить</xButton>
        </div>
        <button class="link" @click="$router.go(-1)">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import note from '@/components/note'
import templates from './templates.json'
import xArrow from '@/components/xArrow';
import {adaptAutomation, adaptNews, scrollTo} from '@/utils';
import {fetchData} from '@/rest/api';


export default {
  components: {
    xArrow,
    textInput,
    xButton,
    note
  },
  name: 'messagesCreateEvents',
  computed: {
    isOnlyMessageSet() {
      return this.form.action_type !== 'Начислить бонусы и отправить сообщение'
    }
  },
  data() {
    return {
      templates: templates,
      statuses: [],
      form: {
        event_value_multiselect: '',
        name: '',
        send_type: 'Сразу',
        send_hours: 3,
        event_type: '',
        event_value_1: '',
        event_value_2: '',
        action_type: 'Начислить бонусы и отправить сообщение',
        bonuses: 500,
        bonuses_burn: 'Да',
        burn_days: 7,
        text: '',
        notify_before_bonuses_burn: 'Нет',
        nbbb_days: 3,
        nbbb_text: '',
        notify_after_bonuses_burn: 'Нет',
        nabb_text: '',
      },
      loading: {
        superText1: false,
        superText2: false,
        superText3: false,
        save: false
      },
      config_event: {
        'Х дней без покупок после вступления': {
          afterword: 'укажите количество дней',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Х дней без покупок': {
          afterword: 'укажите количество дней',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Х дней с момента первой покупки': {
          afterword: 'укажите количество дней',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'День рождения': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Обычная очередная покупка': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Совершение покупки №': {
          afterword: 'укажите номер покупки',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Совершение покупки на сумму от': {
          afterword: 'укажите сумму',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Совершение покупки накопительно на общую сумму': {
          afterword: 'укажите сумму',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Совершение покупки накопительно на общую сумму X за Y дней': {
          afterword: 'укажите за какой период в днях',
          afterword2: 'укажите сумму',
          input: {type: 'integer', min: 1},
          input2: {type: 'integer', min: 1},
          inputValue: '',
          inputValue2: '',
          wrap: true
        },
        'Достижение статуса в системе лояльности': {
          afterword: '',
          input: {type: 'multiselect'},
          inputValue: '',
          wrap: true
        },
        'Клиент отправил команду "Рекомендовать"': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Друг по рекомендации прошел регистрацию': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Друг по рекомендации сделал покупку': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'X друзей по рекомендации сделали покупки': {
          afterword: 'укажите количество друзей',
          input: {type: 'integer', min: 1},
          inputValue: ''
        }
      },
      inputOptions: {
        send: [
          'Начислить бонусы и отправить сообщение',
          'Отправить сообщение'
        ],
        timer: [
          'Сразу',
          'Через',
          'За'
        ],
        loyal: [
          'Бронзовый',
          'Серебряный',
          'Золото'
        ],
        burn_bonuses_action: [
          'Да',
          'Нет'
        ],
        reminder: [
          'Да',
          'Нет'
        ],
        notify: [
          'Да',
          'Нет'
        ]
      },
      mode: 'create',
      textValidation: {
        superText1: true,
        superText2: true,
        superText3: true,
        name: true,
      }
    }
  },
  methods: {
    async fetchTest(textMessage) {
      let url = ''
      const requestForm = this.getRequestForm()
      this.textValidation.superText1 = true
      this.textValidation.superText2 = true
      this.textValidation.superText3 = true

      switch (textMessage) {
        case 'superText1': {
          url = 'automation/test/message'
          if (!requestForm.get('text')) {
            this.textValidation.superText1 = false
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка, заполните текст сообщения',
              desc: '',
              status: 'error'
            })
            scrollTo('#input-superText1')
            return
          }
          this.loading.superText1 = true
          break;
        }
        case 'superText2': {
          url = 'automation/test/n1'
          if (!requestForm.get('nbbb_text')) {
            this.textValidation.superText2 = false
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка, заполните сообщение о скором сгорании бонусов',
              desc: '',
              status: 'error'
            })
            scrollTo('#input-superText2')
            return
          }
          this.loading.superText2 = true
          break;
        }
        case 'superText3': {
          url = 'automation/test/n2'
          if (!requestForm.get('nabb_text')) {
            this.textValidation.superText3 = false
            this.$store.commit('animateStore/animateNotification', {
              text: 'Ошибка, заполните сообщение о факте сгорании бонусов',
              desc: '',
              status: 'error'
            })
            scrollTo('#input-superText3')
            return
          }
          this.loading.superText3 = true
          break;
        }
      }

      const resp = await fetchData(url, {
        method: 'POST',
        body: requestForm
      })
      if (resp?.success) {
        this.$store.commit('animateStore/animateNotification', {text: 'Тест успешно отправлен'})
      } else {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка при отправке теста',
          desc: resp.message,
          status: 'error'
        })
      }
      this.loading.superText1 = false
      this.loading.superText2 = false
      this.loading.superText3 = false
    },
    async saveAutomation() {
      const requestForm = this.getRequestForm()
      this.textValidation.name = true
      this.textValidation.superText1 = true

      if (!requestForm.get('name')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните название',
          desc: '',
          status: 'error'
        })
        this.textValidation.name = false
        scrollTo('#form-name')
        return
      }
      if (!requestForm.get('event_type')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, выберите событие',
          desc: '',
          status: 'error'
        })
        scrollTo('#form-event_type')
        return
      }
      if (!requestForm.get('text')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните текст',
          desc: '',
          status: 'error'
        })
        scrollTo('#form-text')
        this.textValidation.superText1 = false
        return
      }
      if (this.form.notify_before_bonuses_burn === 'Да' && !requestForm.get('nbbb_text')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните текст',
          desc: '',
          status: 'error'
        })
        scrollTo('#form-text-2')
        this.textValidation.superText2 = false
        return
      }
      if (this.form.notify_after_bonuses_burn === 'Да' && !requestForm.get('nabb_text')?.length) {
        this.$store.commit('animateStore/animateNotification', {
          text: 'Ошибка, заполните текст',
          desc: '',
          status: 'error'
        })
        scrollTo('#form-text-3')
        this.textValidation.superText3 = false
        return
      }

      this.loading.save = true

      if (this.mode === 'create') {
        const resp = await fetchData('automation', {
          method: 'POST',
          body: requestForm
        })
        if (resp?.success) {
          this.$router.push('/admin/messages/automation')
          this.$store.commit('animateStore/animateNotification', {text: 'Автоматизация успешно создана'})
        } else {
          this.$store.commit('animateStore/animateNotification', {
            text: 'Ошибка при создании автоматизации',
            desc: resp.message,
            status: 'error'
          })
        }
      } else {
        const automationId = this.$route.params.id
        requestForm.append('_method', 'PUT')
        const resp = await fetchData(`automation/${automationId}`, {
          method: 'POST',
          body: requestForm
        })
        if (resp?.success) {
          this.$router.push('/admin/messages/automation')
          this.$store.commit('animateStore/animateNotification')
        } else {
          this.$store.commit('animateStore/animateNotification', {
            text: 'Ошибка при изменении автоматизации',
            desc: resp.message,
            status: 'error'
          })
        }
      }
      this.loading.save = false

    },
    getRequestForm() {
      this.form.text = this.$refs.superText1.innerHTML === '<br>' ? '' : this.$refs.superText1.innerHTML
      this.form.nbbb_text = this.$refs.superText2?.innerHTML === '<br>' ? '' : this.$refs.superText2.innerHTML
      this.form.nabb_text = this.$refs.superText3?.innerHTML === '<br>' ? '' : this.$refs.superText3.innerHTML

      const formToSend = adaptAutomation(JSON.parse(JSON.stringify(this.form)), true, this.config_event, this.statuses)


      const requestForm = new FormData()

      Object.keys(formToSend).forEach((key) => {
        requestForm.append(key, formToSend[key])
      })

      return requestForm
    },
    insertText(html, selectPastedContent) {
      let sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment();
          let node;
          let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          const firstNode = frag.firstChild
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            if (selectPastedContent) {
              range.setStartBefore(firstNode)
            } else {
              range.collapse(true)
            }
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange()
        originalRange.collapse(true)
        sel.createRange().pasteHTML(html)
        if (selectPastedContent) {
          range = sel.createRange()
          range.setEndPoint('StartToStart', originalRange)
          range.select()
        }
      }
    },
    setTemplates(event_type) {
      if (!this.$refs.superText2 && !this.$refs.superText3) return
      this.$refs.superText2.innerHTML = this.config_event[event_type]?.template_soon_burn || '';
      this.$refs.superText3.innerHTML = this.config_event[event_type]?.template_burned || '';
    },
    deleteText(e) {
      if ((e.path && e.path[0]?.className === 'link clicker-link') || e.target.className === 'link clicker-link') {
        e.path ? e.path[0].innerHTML = '' : e.target.innerHTML = ''
      }
    }
  },
  async mounted() {
    const automationId = this.$route.params.id
    if (this.$route.name === 'editAutomation' && automationId) {
      this.mode = 'edit'
      const {data} = await fetchData(`automation/${automationId}/edit`, {
        method: 'GET',
      })

      this.form = adaptAutomation(data, false, this.config_event, this.statuses)
      if (data) {
        this.$refs.superText1.innerHTML = this.form.text
        this.$refs.superText2.innerHTML = this.form.nbbb_text
        this.$refs.superText3.innerHTML = this.form.nabb_text
      }
    }
  },
  async created() {
    const keys = Object.keys(this.config_event)
    keys.forEach((key) => {
      this.config_event[key].note = templates.config_event[key].note
      this.config_event[key].template_default = templates.config_event[key].template_default
      this.config_event[key].template_soon_burn = templates.config_event[key].template_soon_burn
      this.config_event[key].template_burned = templates.config_event[key].template_burned
      this.config_event[key].template_only_message = templates.config_event[key].template_only_message
      this.config_event[key].autofields = templates.config_event[key].autofields
    })
    const {data} = await fetchData('automation/getStatusesList', {method: 'POST'})
    this.statuses = data?.statuses.map(item => {
      return {id: item.id, name: item.name}
    }) || []


  }
}
</script>

<style lang="scss" src="../messages.scss" scoped/>
<style lang="scss" src="../../../components/textInput/textInput.scss" scoped/>
<style lang="scss" src="./messagesManageAutomation.scss" scoped/>
