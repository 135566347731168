<template>
  <div class="layout-1">
    <div class="clients-total analytics-block">
      <div class="columns-4">
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Всего клиентов</div>
          <router-link class="link columns-4-link" to="/admin/clients">1 316</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Выручка с клиентов</div>
          <router-link class="link columns-4-link" to="/admin/operations">2 157 817 руб.</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Средний чек покупки</div>
          <router-link class="link columns-4-link" to="/admin/operations">536,5 руб.</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Средняя частота покупок</div>
          <router-link class="link columns-4-link" to="/admin/operations">11 раз в год</router-link>
        </div>

        <div class="columns-4-item">
          <div class="columns-4-item-desc">Средний <br> возраст</div>
          <router-link class="link columns-4-link" to="/admin/clients">38,5</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Не закончили <br> регистрацию</div>
          <router-link class="link columns-4-link" to="/admin/clients">217</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Делали покупки <br> (1 покупка и более)</div>
          <router-link class="link columns-4-link" to="/admin/clients">1 051</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Постоянные клиенты <br> (3 покупки и более)</div>
          <router-link class="link columns-4-link" to="/admin/clients">611</router-link>
        </div>
      </div>
    </div>
    <div class="clients-connect analytics-block">
      <h2 class="title connect-title">
        По источнику подключения
      </h2>
      <div class="connect-info">
        <p class="connect-info-item">
          Подключены в бизнесе: <router-link class="link connect-link" to="/admin/clients">1002</router-link>
        </p>
        <p class="connect-info-item">
          Пришли по рекомендации: <router-link class="link connect-link" to="/admin/clients">314</router-link>
        </p>
      </div>
      <div class="analytics-graph" >
        <VueHighcharts
          type="chart"
          :options="sourceOptions"
          :animateOnUpdate="true"
          />
      </div>
      <p class="clients-desc">
        Нажмите на цифру – посмотрите список этих клиентов.
      </p>
    </div>
    <div class="clients-segments analytics-block">
      <div class="segments-container">
        <div class="segments-left">
          <h2 class="title segments-title">
            Средний возраст клиентов —' 28,5 лет',
          </h2>
          <div class="analytics-graph" style="width: 650px">
            <VueHighcharts
              type="chart"
              :options="averageYearOptions"
              :animateOnUpdate="true"
            />
          </div>
        </div>
        <div class="segments-right">
          <h2 class="title segments-title">
            Пол клиентов
          </h2>
          <div class="analytics-graph"  >
            <VueHighcharts
              type="chart"
              :options="averageSexOptions"
              :animateOnUpdate="true"
            />
          </div>
        </div>
      </div>
      <p class="clients-desc">
        Нажмите на цифру – посмотрите список этих клиентов.
      </p>
    </div>
    <div class="clients-top analytics-block">
      <div class="clients-top-purchases">
        <h2 class="title top-title">
          Топ-10 по покупкам
        </h2>
        <div class="clients-top-table">
          <xTable v-bind="getTableDataPurchases()" />
        </div>
        <router-link class="clients-top-button" to="/admin/clients">Смотреть всех</router-link>
      </div>
      <div class="clients-top-recommendations">
        <h2 class="title top-title">
          Топ-10 по рекомендациям
        </h2>
        <div class="clients-top-table">
          <xTable v-bind="getTableDataRecommendations()" />
        </div>
        <router-link class="clients-top-button" to="/admin/clients">Смотреть всех</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import xTable from '@/components/xTable'
import analyticsTopPurchases from '@/views/analytics/analyticsClients/analyticsTopPurchases.json'
import analyticsTopRecommendations from '@/views/analytics/analyticsClients/analyticsTopRecommendations.json'
import VueHighcharts from 'vue3-highcharts'
import { computed, watch, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'analyticsClients',
  setup () {
    const router = useRouter()
    const sourceData = ref([1002, 154, 89, 54, 17])
    const averageYearData = ref([125, 154, 201, 226, 192, 163, 138, 89, 25])
    const averageSexData = ref([601, 669, 140])
    const columnRoutesData = ref()
    const dynamicColors = ref([])
    const dynamicCategories = ref([])

    dynamicColors.value[0] = '#552490'
    for (let i = 1; i < 5; i++) {
      dynamicColors.value[i] = '#FF7F27'
      dynamicCategories.value[i - 1] = ''
    }
    for (let i = 0; i < 5; i++) {
      dynamicCategories.value[i] = ''
    }

    const sourceOptions = computed(() => ({
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: dynamicCategories.value
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      colors: dynamicColors.value,
      plotOptions: {

        column: {
          colorByPoint: true,
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }

        },
        series: {
          cursor: 'pointer',
          pointWidth: 130,

          events: {
            click: function (event) {
              columnRoutesData.value = event.point.options.y
            }
          }
        }
      },
      series: [{
        data: sourceData.value
      }]
    }))
    const averageYearOptions = computed(() => ({
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: ['21-25 лет', '26-30 лет', '31-35 лет', '36-40 лет', '41-45 лет', '46-50 лет', '51-55 лет', '56-60 лет', '61-65 лет']

      },
      colors: ['#552490'],
      yAxis: {
        title: {
          text: ''
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }

        },
        series: {
          cursor: 'pointer',

          events: {
            click: function (event) {
              columnRoutesData.value = event.point.options.y
            }
          }
        }
      },
      series: [{
        data: averageYearData.value
      }]
    }))
    const averageSexOptions = computed(() => ({
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: null
      },
      xAxis: {
        categories: ['Мужской', 'Женский', 'Информация отсутствует']

      },
      colors: ['#552490', '#FF7F27'],
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }

        },
        series: {
          cursor: 'pointer',

          events: {
            click: function (event) {
              columnRoutesData.value = event.point.options.y
            }
          }
        }
      },
      series: [{
        data: averageSexData.value
      }]
    }))
    watch(columnRoutesData, () => {
      router.push('/admin/clients')
    })

    return {
      sourceOptions,
      averageYearOptions,
      averageSexOptions
    }
  },
  components: {
    xTable,
    VueHighcharts
  },
  methods: {
    getTableDataPurchases () {
      return {
        theads: 0,
        analyticsTopPurchases: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: analyticsTopPurchases,
        optionsX: [
          { name: '', value: 'number', id: 0 },
          { name: 'Клиент', value: 'clientName', id: 1 },
          { name: 'Кол-во покупок', value: 'clientPurchases', id: 2 },
          { name: 'Выручка с клиента', value: 'clientRevenue', id: 3 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['', 'Клиент', 'Кол-во покупок', 'Выручка с клиента'],
        namesForHeaderX: []
      }
    },
    getTableDataRecommendations () {
      return {
        theads: 0,
        analyticsTopPurchases: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: analyticsTopRecommendations,
        optionsX: [
          { name: '', value: 'number', id: 0 },
          { name: 'Клиент', value: 'clientName', id: 1 },
          { name: 'Кол-во рек-ций', value: 'clientRecommendationsNumber', id: 2 },
          { name: 'Эфф. рек-ций', value: 'clientRecommendationsEffect', id: 3 },
          { name: 'Выручка с клиента', value: 'clientRevenue', id: 4 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['', 'Клиент', 'Кол-во рек-ций', 'Эфф. рек-ций', 'Выручка с клиента'],
        namesForHeaderX: []
      }
    }
  }
}
</script>

<style lang="scss" src="./analyticsClients.scss" scoped />
<style lang="scss" src="../analytics.scss" scoped/>
