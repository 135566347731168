<template>
  <div class="integrations-container">
    <div class="layout-1 integrations-documentation-api">
      <h2 class="title note-wrapper" style="z-index: 9">
        Документация API
        <note note-position="bottom-to-right">
          <b>
            Сплочённость команды профессионалов продолжает удивлять.
          </b>
          <br><br>
          Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения.
          <br><br>
          Предложения складываются в абзацы – и вы наслаждетесь очередным бредошедевром.
          <br><br>
          Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей"
        </note>
      </h2>
      <div class="integrations-documentation">
        <div class="x-input-item">
          <div class="input-name">ID компании</div>
          <div class="input-content">
            <textInput :disabled="true" placeholder="Введите ID компании" :defaultValue="companyId"/>
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">API-ключ</div>
          <div class="input-content">
            <textInput :disabled="true" placeholder="Введите API-ключ" :defaultValue="key"/>
          </div>
          <div @click="generateNewKey" class="link documentation-link">Сгенерировать новый</div>
        </div>
      </div>
    </div>
    <div class="layout-1">
      <h2 class="title">Интеграция с кассами / сайтами</h2>
      <div class="documentation-variants">
        <div class="documentation-variants-item">
          <img src="../../../../assets/img/evotor-logo.svg" alt="Партнер Эвотор" class="documentation-variants-img">
          <div class="documentation-variants-text">
            <h3 class="title">Эвотор</h3>
            <p class="documentation-variants-desc">Инструкция по настройке интеграции с Эвотор</p>
          </div>
        </div>
        <div class="documentation-variants-item">
          <img src="../../../../assets/img/mts-logo.svg" alt="Партнер МТС" class="documentation-variants-img">
          <div class="documentation-variants-text">
            <h3 class="title">МТС-Касса</h3>
            <p class="documentation-variants-desc">Инструкция по настройке интеграции с МТС-Касса</p>
          </div>
        </div>
        <div class="documentation-variants-item">
          <img src="../../../../assets/img/1C-logo.svg" alt="Партнер 1С" class="documentation-variants-img">
          <div class="documentation-variants-text">
            <h3 class="title">1С Управление торговлей 11</h3>
            <p class="documentation-variants-desc">Пример расширения для 1С УТ 11</p>
          </div>
        </div>
        <div class="documentation-variants-item">
          <img src="../../../../assets/img/1C-logo.svg" alt="Партнер 1С" class="documentation-variants-img">
          <div class="documentation-variants-text">
            <h3 class="title">1С Розница 2.3</h3>
            <p class="documentation-variants-desc">Пример расширения для 1С Розница 2.3</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import note from '@/components/note'

export default {
  name: 'settingsIntegrationsWeb',
  components: {
    textInput,
    note
  },
  data () {
    return {
      companyId: '',
      key: ''
    }
  },
  methods: {
    generateNewKey () {
      this.$store.commit(
        'popupStore/show',
        {
          title: 'Генерации нового API-ключа',
          text: 'После генерации нового API-ключа все сделанные интеграции будут разорваны',
          buttonText: 'Сгенерировать новый',
          rollBackButtonText: 'Оставить старый',
          useFullWindow: false,
          callback: () => {
            fetch(window.host + 'users/integration/kassa', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access_token')
              }
            }).then(async response => {
              const data = await response.json()

              if (!response.ok || !data.success) {
                const error = (data && data.message) || response.status
                return Promise.reject(error)
              }

              this.key = data.data
              this.$store.commit('animateStore/animateNotification')
            }).catch(err => {
              this.$store.commit('techStore/checkError', { err })
            })
          }
        }
      )
    }
  },
  created () {
    fetch(window.host + 'users/integration/kassa', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      if (
        // eslint-disable-next-line dot-notation
        typeof data.data['companyId'] !== 'undefined' && data.data['companyId'] &&
        // eslint-disable-next-line dot-notation
        typeof data.data['apiKey'] !== 'undefined' && data.data['apiKey']
      ) {
        this.companyId = data.data.companyId
        this.key = data.data.apiKey
      }
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./settingsIntegrationsWeb.scss" scoped />
