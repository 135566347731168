<template>
    <transition>
      <div v-show="this.$store.state.animateStore.isShowNotification" class="notification">
        <div class="notification-icon">
          <template v-if="this.$store.state.animateStore.status === 'error'">
            <icon name="alert" class="error" />
          </template>
          <template v-else>
            <icon name="checkboxIcon" class="notification-icon" />
          </template>
        </div>
        <div class="notification-info">
          <div class="notification-title">
            {{
              this.$store.state.animateStore.notificationTitle ||
              (this.$store.state.animateStore.status === 'error' ? 'Что-то пошло не так' : 'Изменения сохранены')
            }}
          </div>
          <div class="notification-desc" v-if="this.$store.state.animateStore.notificationDesc || this.$store.state.animateStore.status === 'error'">
            {{
              this.$store.state.animateStore.notificationDesc === '' ? this.$store.state.animateStore.notificationDesc : this.$store.state.animateStore.notificationDesc ||
              'Пожалуйста, попробуйте снова или повторите попытку позже.'
            }}
          </div>
        </div>
        <div class="notification-close">
          <icon name="close" @click="this.$store.commit('animateStore/closeNotification')"/>
        </div>
      </div>
    </transition>
</template>

<script>
import Icon from '@/components/icons/icon'
export default {
  name: 'notification',
  components: {
    Icon
  }
}
</script>

<style lang="scss" src="./notification.scss" scoped />
