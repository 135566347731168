<template>
  <adminNav>
    <router-link :to="`/admin/customer/${id}/information`">
      Информация
    </router-link>
    <router-link :to="`/admin/customer/${id}/purchases`">
      Покупки
    </router-link>
    <router-link :to="`/admin/customer/${id}/bonuses`">
      Бонусы
    </router-link>
    <router-link :to="`/admin/customer/${id}/recommendations`">
      Рекомендации
    </router-link>
    <router-link :to="`/admin/customer/${id}/messages`">
      Сообщения
    </router-link>
  </adminNav>
  <router-view @titleName="handleName" :key="id" />
</template>

<script>
import adminNav from '@/components/adminNav'

export default {
  name: 'customer',
  components: {
    adminNav
  },
  data () {
    return {
      id: 0
    }
  },
  emits: ['titleName'],
  watch: {
    // For redirect between dynamic routes
    '$route.params.id': {
      immediate: true, // Runs on component mount
      handler(newId) {
        if (isNaN(parseInt(newId))) {
          this.$router.push({ name: 'error' });
        } else {
          this.id = newId;
        }
      }
    }
  },
  methods: {
    handleName (name) {
      this.$emit('titleName', name)
    }
  }
}
</script>

<style lang="scss" src="./customer.scss" scoped />
