export const adaptNews = (data, mode) => {
  const form = {}
  form.bonuses = data.bonuses
  form.burn_days = data.burn_days
  form.nabb_text = data.nabb_text
  form.nbbb_text = data.nbbb_text
  form.nbbb_days = data.nbbb_days
  form.text = data.text
  form.title = data.title
  form.image = data.image

  if (mode) {
    form.action_type = data.action_type === 'Начислить бонусы и отправить сообщение' ? 0 : 1
    form.send_type = data.send_type === 'Сразу' ? 0 : 1
    form.bonuses_burn = data.bonuses_burn === 'Нет' ? 0 : 1
    form.notify_before_bonuses_burn = data.notify_before_bonuses_burn === 'Нет' ? 0 : 1
    form.notify_after_bonuses_burn = data.notify_after_bonuses_burn === 'Нет' ? 0 : 1


    if (!form.send_type) {
      delete form.send_date
      delete form.send_time
    } else {
      form.send_date = data.send_date
      form.send_time = data.send_time

    }
  } else {
    form.action_type = data.action_type === 0 ? 'Начислить бонусы и отправить сообщение' : 'Отправить сообщение'
    if (data.send_type) {
      const [day, month, year] = data.send_date.split('.');
      form.send_date = `${year}-${month}-${day}`
      form.send_time = data.send_time
    }
    form.send_type = data.send_type === 0 ? 'Сразу' : 'Запланировать'
    form.bonuses_burn = data.bonuses_burn === 0 ? 'Нет' : 'Да'
    form.notify_before_bonuses_burn = data.notify_before_bonuses_burn === 0 ? 'Нет' : 'Да'
    form.notify_after_bonuses_burn = data.notify_after_bonuses_burn === 0 ? 'Нет' : 'Да'

  }

  return form
}

const daysIDs = [0, 1, 2, 8]
const valuesIDs = [5, 6, 7, 8, 9, 13]

export const adaptAutomation = (data, mode, config_event, statuses = []) => {
  const form = {}
  form.name = data.name
  form.bonuses = data.bonuses
  form.burn_days = data.burn_days
  form.nabb_text = data.nabb_text
  form.nbbb_text = data.nbbb_text
  form.nbbb_days = data.nbbb_days
  form.text = data.text
  form.send_hours = data.send_hours


  if (mode) {
    const events = Object.keys(config_event)

    form.event_type = events.findIndex((event) => event === data.event_type)

    form.action_type = data.action_type === 'Начислить бонусы и отправить сообщение' ? 0 : 1
    switch (data.send_type) {
      case 'Сразу': {
        form.send_type = 0
        break;
      }
      case 'Через': {
        form.send_type = 1
        break;
      }
      case 'За': {
        form.send_type = 2
        break;
      }
    }
    form.bonuses_burn = data.bonuses_burn === 'Нет' ? 0 : 1
    form.notify_before_bonuses_burn = data.notify_before_bonuses_burn === 'Нет' ? 0 : 1
    form.notify_after_bonuses_burn = data.notify_after_bonuses_burn === 'Нет' ? 0 : 1


    if (!form.send_type) {
      delete form.send_hours
    } else {
      form.send_hours = data.send_hours
    }

    if (data.event_value_1) {
      if (daysIDs.includes(form.event_type)) form.event_days = data.event_value_1
      if (valuesIDs.includes(form.event_type)) form.event_value = data.event_value_1
    }
    if (data.event_value_2) {
      form.event_value = data.event_value_1
      form.event_days = data.event_value_2
    }

    if (data.event_value_multiselect) {
      const {id} = statuses.find((status) => status.name === data.event_value_multiselect)
      form.event_value = id
    }

  } else {
    const events = Object.keys(config_event)

    form.event_type = events[data.event_type]

    form.action_type = data.action_type === 0 ? 'Начислить бонусы и отправить сообщение' : 'Отправить сообщение'
    if (data.send_type) {
      form.send_hours = data.send_hours
    }
    switch (data.send_type) {
      case 0: {
        form.send_type = 'Сразу'
        break;
      }
      case 1: {
        form.send_type = 'Через'
        break;
      }
      case 2: {
        form.send_type = 'За'
        break;
      }
    }
    form.bonuses_burn = data.bonuses_burn === 0 ? 'Нет' : 'Да'
    form.notify_before_bonuses_burn = data.notify_before_bonuses_burn === 0 ? 'Нет' : 'Да'
    form.notify_after_bonuses_burn = data.notify_after_bonuses_burn === 0 ? 'Нет' : 'Да'

    if (data.event_days) {
      if (daysIDs.includes(data.event_type)) form.event_value_1 = data.event_days
    }
    if (data.event_value) {
      if (valuesIDs.includes(data.event_type)) form.event_value_1 = data.event_value
      const item = statuses.find((status) => status.id === data.event_value)
      if (item) {
        form.event_value_multiselect = item.name
      }
    }

    if (data.event_days && data.event_value) {
      form.event_value_1 = data.event_days
      form.event_value_2 = data.event_value
    }


  }

  return form
}

export const scrollTo = (selector) => {
  setTimeout(() => {
    const firstErrElem = window.document.querySelector(selector)

    if (firstErrElem) {
      const offset = window.scrollY + firstErrElem.getBoundingClientRect().top - 100
      window.scroll({
        top: offset < 0 ? 0 : offset,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, 100)
}
