<template>
  <div class="clients-header">
    <div class="clients-filter">
      <xFilter
        :rowCount="rowCount"
        @onExcelExport="onExcelExport"
        @apply="requestClients"
        @reset="resetValues"
      >
        <xFilterItem
          v-for="item in tags"
          :key="item.id"
          :inputNumber="item.number"
          :item="item"
          @logChange="filtering"/>
      </xFilter>
    </div>
    <div class="clients-search">
      <textInput
        placeholder="Введите фамилию клиента или номер телефона..."
        autocomplete="off"
        @onChange="searchString"
      >
        <template v-slot:before>
          <button class="search-button">
            <icon class="search-icon" name="search"/>
          </button>
        </template>
      </textInput>
    </div>
  </div>
  <tags :tags="tags" :tagsCount="tagsCount" @deleteTag="deletingTag"/>
  <xTable
    v-if="tableData.data.length"
    v-bind="tableData"
    @titleName="routeClick"
    @onSort="sortAction"
    @onPageChange="requestClientsByPage"
  >
    <td></td>
  </xTable>
  <div v-else class="empty-list">
    Список клиентов пока пуст
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import xFilter from '@/components/xFilter'
import textInput from '@/components/textInput'
import xTable from '@/components/xTable'
import xFilterItem from '@/components/xFilterItem'
import tags from '@/components/tags'

const searchByString = debounce((callback) => {
  callback()
}, 1000)

export default {
  name: 'clients',
  components: {
    xFilter,
    textInput,
    xTable,
    xFilterItem,
    tags
  },
  data: () => ({
    sort: {
      name: 'Дата регистрации',
      type: 'desc'
    },
    searchQuery: '',
    tagsCount: 0,
    rowCount: 0,
    tags: [
      {
        name: 'Пол',
        number: 2,
        type: 'checkbox',
        code: 'gender',
        filterText1: 'Мужской пол',
        filterText2: 'Женский пол',
        text1: 'Мужской',
        text2: 'Женский',
        value1: 'male',
        value2: 'female'
      },
      {
        name: 'Средний чек',
        number: 3,
        type: 'checkbox',
        code: 'averageCheck',
        filterText1: 'Высокий средний чек',
        filterText2: 'Средний средний чек',
        filterText3: 'Низкий средний чек',
        text1: 'Высокий средний чек',
        text2: 'Средний средний чек',
        text3: 'Низкий средний чек',
        value1: 'high',
        value2: 'middle',
        value3: 'low'
      },
      {
        name: 'Частота покупок',
        number: 3,
        type: 'checkbox',
        code: 'frequency',
        filterText1: 'Покупает часто',
        filterText2: 'Покупает не так часто',
        filterText3: 'Покупает редко',
        text1: 'Покупает часто',
        text2: 'Не так часто',
        text3: 'Редко',
        value1: 'often',
        value2: 'not_so_often',
        value3: 'rarely'
      },
      {
        name: 'Давность покупки',
        number: 3,
        type: 'checkbox',
        code: 'prescription',
        filterText1: 'Покупал недавно',
        filterText2: 'Покупал не так давно',
        filterText3: 'Покупал давно',
        text1: 'Недавно',
        text2: 'Не так давно',
        text3: 'Давно',
        value1: 'recently',
        value2: 'not_so_long_ago',
        value3: 'long_ago'
      },
      {
        name: 'Канал связи',
        number: 3,
        type: 'checkbox',
        code: 'channel',
        text1: 'Телеграмм',
        text2: 'ВКонтанке',
        text3: 'Viber',
        value1: 'telegram',
        value2: 'vk',
        value3: 'viber'
      },
      {
        name: 'Статус',
        number: 0,
        type: 'checkbox',
        code: 'clientStatus'
      },
      {
        name: 'Дата регистрации',
        type: 'date',
        code: 'register_date'
      },
      {
        name: 'Количество покупок',
        type: 'number',
        code: 'buy_count'
      },
      {
        name: 'Сумма покупок',
        type: 'number',
        code: 'purchasesNumber'
      },
      {
        name: 'Источник',
        number: 3,
        type: 'input',
        code: 'referralName',
        value: '',
        output: ''
      },
      {
        name: 'Возраст',
        type: 'number',
        code: 'age',
        value: '',
        output: ''
      },
      {
        name: 'Последняя покупка',
        type: 'date',
        code: 'last_buy'
      },
      {
        name: 'Количество бонусов',
        type: 'number',
        code: 'bonuses_amount',
        value: '',
        output: ''
      },
      {
        name: 'Уровень',
        type: 'number',
        code: 'level',
        value: '',
        output: ''
      }
    ],
    statusesData: [],
    tableData: {
      currentPageX: 1,
      selectedRowsX: [],
      totalPagesX: 1,
      pageSizeX: 1,
      data: [],
      clientsRoutes: true,
      optionsX: [
        {name: 'Клиент', value: 'name', id: 0},
        {name: 'Пол', value: 'gender', id: 1},
        {name: 'Дата регистрации', value: 'regDate', id: 2},
        {name: 'Уровень', value: 'level', id: 3},
        {name: 'Кол-во рекомендаций', value: 'reactionNumber', id: 4},
        {name: 'Остаток бонусов', value: 'bonusNumber', id: 5},
        {name: 'Сумма покупок', value: 'purchasesNumber', id: 6},
        {name: 'Источник', value: 'referralName', id: 7},
        {name: 'Статус', value: 'clientStatus', id: 8},
        {name: 'Группа Средний чек', value: 'averageCheck', id: 9},
        {name: 'Группа частота', value: 'averageFreq', id: 10},
        {name: 'Группа давность', value: 'averageTerm', id: 11},
        {name: 'Канал связи', value: 'channel', id: 12},
        {name: 'ID', value: 'clientID', id: 13}
      ],
      allNamesX: ['Клиент', 'Пол', 'Дата регистрации', 'Уровень', 'Кол-во рекомендаций', 'Остаток бонусов', 'Сумма покупок', 'Источник', 'Статус', 'Группа Средний чек', 'Группа частота', 'Группа давность', 'Канал связи', 'ID'],
      newNamesX: [],
      importantNamesX: ['Клиент'],
      defaultNamesX: ['Клиент', 'Пол', 'Дата регистрации', 'Уровень', 'Остаток бонусов', 'Сумма покупок', 'Источник', 'Статус', 'Канал связи'],
      namesForHeaderX: [],
      useSort: true,
      defaultSortName: 'Дата регистрации',
      defaultSort: 'desc'
    }
  }),
  computed: {
    getFilters() {
      const filter = {}

      for (const i in this.tags) {
        const tag = this.tags[i]

        if (tag.type === 'checkbox') {
          // eslint-disable-next-line no-case-declarations
          const tagValue = []

          let maxI = 0
          for (const key of Object.keys(tag)) {
            const onlyDigits = key.replace(/\D/gmu, '')
            if (onlyDigits.length) {
              const digitsInt = parseInt(onlyDigits)
              if (digitsInt > maxI) {
                maxI = digitsInt
              }
            }
          }

          if (maxI) {
            for (let j = 1; j <= maxI; j++) {
              if (tag[`check${j}`]) {
                tagValue.push(tag[`value${j}`])
              }
            }
          }

          if (tagValue.length) {
            filter[tag.code] = tagValue
          }
        } else if (tag.type === 'input') {
          if (tag.value.trim().length) {
            filter[tag.code] = tag.value.trim()
          }
        } else {
          if (tag.valueFrom) {
            filter[`${tag.code}_from`] = tag.valueFrom
          }

          if (tag.valueTo) {
            filter[`${tag.code}_to`] = tag.valueTo
          }
        }
      }

      const result = {
        searchQuery: this.searchQuery,
        filter
      }

      const sortField = this.tableData.optionsX.find(el => el.name === this.sort.name)
      if (sortField) {
        result.sort = {
          name: sortField.value,
          type: this.sort.type
        }
      }

      return result
    },
    getPreparedOnlyFilter() {
      const filters = this.getFilters

      const filterForRequest = []
      for (const key in filters.filter) {
        const val = filters.filter[key]
        if (Array.isArray(val)) {
          for (const innerVal of val) {
            filterForRequest.push(`${key}[]=${innerVal}`)
          }
        } else {
          filterForRequest.push(`${key}=${val}`)
        }
      }

      return filterForRequest
    },
    getPreparedFilterForRequest() {
      const filters = this.getFilters

      const filterForRequest = [...this.getPreparedOnlyFilter]

      const searchQuery = filters.searchQuery.trim()
      if (searchQuery.length) {
        filterForRequest.push(`searchQuery=${searchQuery}`)
      }

      const sort = filters.sort
      const sortMapNames = {
        name: 'name',
        gender: 'gender',
        clientID: 'id',
        regDate: 'created_at',
        level: 'level',
        reactionNumber: 'reaction_number',
        bonusNumber: 'bonus_number',
        purchasesNumber: 'purchases_number',
        referralName: 'referral_client_id',
        clientStatus: 'status',
        averageCheck: 'average_check',
        averageFreq: 'frequency',
        averageTerm: 'antiquity',
        frequency: 'frequency',
        prescription: 'prescription'
      }

      filterForRequest.push(`sort=${sortMapNames[sort.name]}`)
      filterForRequest.push(`order=${sort.type}`)

      return filterForRequest
    }
  },
  methods: {
    onExcelExport() {
      fetch(window.host + 'excel/code', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (!data.data) {
          throw Error('Нет авторизационного кода')
        }

        const preparedFilter = [...this.getPreparedOnlyFilter]
        preparedFilter.push('uid=' + data.data)
        const uri = window.host + 'excel/clients?' + preparedFilter.join('&')

        const link = document.createElement('a')
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    setClients(arrClients) {
      this.tableData.data = arrClients.map(el => {
        let gender = '─'
        if (el.gender === 'male') {
          gender = 'Мужской'
        }
        if (el.gender === 'female') {
          gender = 'Женский'
        }
        return {
          name: el.name || '─',
          gender,
          regDate: el.created_at || '─',
          level: el.level_all || '─',
          reactionNumber: el.reaction_number || '─',
          bonusNumber: el.bonus_number || '─',
          purchasesNumber: el.purchases_number || '─',
          referralName: el.referral_client?.name || '─',
          clientStatus: el.status || '─',
          averageCheck: el.average_check || 'Нет покупок',
          averageFreq: el.frequency || 'Нет покупок',
          averageTerm: el.antiquity || 'Нет покупок',
          channel: el.channel || false,
          clientID: el.id || '─'
        }
      })
    },
    requestClientsByPage(pageNumber) {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      preparedFilter.push(`page=${pageNumber}`)

      fetch(window.host + 'clients?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.setClients(data.data.clients)

        this.tableData.totalPagesX = data.data.paginate.last_page
        this.tableData.pageSizeX = data.data.paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    requestClients(prefilter = []) {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      if (prefilter.length) {
        prefilter.forEach((item) => {
          preparedFilter.push(item)
        })
      }
      fetch(window.host + 'clients?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.setClients(data.data.clients)

        this.tableData.totalPagesX = data.data.paginate.last_page
        this.tableData.pageSizeX = data.data.paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    requestRowCount() {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      preparedFilter.push('prefilter=y')

      fetch(window.host + 'clients?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.rowCount = data.data?.count || 0
      }).catch(err => {
        this.$store.commit('techStore/checkError', {err})
      })
    },
    sortAction(obj) {
      this.sort.name = obj.name
      this.sort.type = obj.type

      this.requestClients()
    },
    searchString(val) {
      this.searchQuery = val
      searchByString(this.requestClients.bind(this))
    },
    filtering(value, name, direct) {
      if (value === '') {
        this.deletingTag(name, direct)
      } else if (!value) {
        this.deletingTag(name, direct)
      } else {
        this.tagsCount += 1

        const selectedTagIndex = this.tags.findIndex(el => el.name === name)

        if (selectedTagIndex !== -1 && this.tags[selectedTagIndex].type === 'checkbox') {
          const filterName = `${this.tags[selectedTagIndex].name}: `
          const selectedDirect = []

          let maxI = 0
          for (const key of Object.keys(this.tags[selectedTagIndex])) {
            const onlyDigits = key.replace(/\D/gmu, '')
            if (onlyDigits.length) {
              const digitsInt = parseInt(onlyDigits)
              if (digitsInt > maxI) {
                maxI = digitsInt
              }
            }
          }

          if (maxI) {
            for (let i = 1; i <= maxI; i++) {
              const checkKey = `check${i}`
              if (this.tags[selectedTagIndex][checkKey] || checkKey === direct) {
                this.tags[selectedTagIndex][`check${i}`] = true
                selectedDirect.push(this.tags[selectedTagIndex][`text${i}`])
              }
            }
          }

          this.tags[selectedTagIndex].output = `${filterName} ${selectedDirect.join(', ')}`
        } else if (selectedTagIndex !== -1 && this.tags[selectedTagIndex].type === 'input') {
          this.tags[selectedTagIndex].value = value
          this.tags[selectedTagIndex].output = `${this.tags[selectedTagIndex].name}: ${value}`
        } else {
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === name) {
              const fromVal = direct === 'from' ? value : (this.tags[i].valueFrom ? this.tags[i].valueFrom : '')
              const toVal = direct === 'to' ? value : (this.tags[i].valueTo ? this.tags[i].valueTo : '')

              this.tags[i].valueFrom = fromVal
              this.tags[i].valueTo = toVal

              const prefixFrom = fromVal ? 'от' : ''
              const prefixTo = toVal ? 'до' : ''

              const output = `${name}: ${prefixFrom} ${fromVal} ${prefixTo} ${toVal}`
              this.tags[i].output = output.replace('  ', ' ')
            }
          }
        }
      }

      this.requestRowCount()
    },
    deletingTag(name, direct) {
      this.tagsCount -= 1
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name === name) {
          if (this.tags[i].type === 'checkbox') {
            let maxI = 0
            for (const key of Object.keys(this.tags[i])) {
              const onlyDigits = key.replace(/\D/gmu, '')
              if (onlyDigits.length) {
                const digitsInt = parseInt(onlyDigits)
                if (digitsInt > maxI) {
                  maxI = digitsInt
                }
              }
            }

            if (!maxI) {
              return
            }
            for (let j = 1; j <= maxI; j++) {
              if (direct === `check${j}` || !direct) {
                this.tags[i][`check${j}`] = false
                this.tags[i][`output${j}`] = ''
              }
            }
            let counter = 0
            const selectedDirect = []
            for (let j = 1; j <= maxI; j++) {
              if (this.tags[i][`check${j}`]) {
                counter++
                selectedDirect.push(this.tags[i][`text${j}`])
              }
            }
            if (counter) {
              this.tags[i].output = `${name}: ${selectedDirect.join(', ')}`
            } else {
              this.tags[i].output = ''
            }

          } else if (this.tags[i].type === 'input') {
            this.tags[i].output = ''
            this.tags[i].value = ''
          } else {
            this.tags[i].output = ''
            this.tags[i].valueTo = ''
            this.tags[i].valueFrom = ''
          }
        }
      }

      this.requestClients()
    },
    resetValues() {
      this.tagsCount = 0
      for (let i = 0; i < this.tags.length; i++) {
        this.tags[i].value = ''
        this.tags[i].valueTo = ''
        this.tags[i].valueFrom = ''
        this.tags[i].output = ''

        let maxI = 0
        for (const key of Object.keys(this.tags[i])) {
          const onlyDigits = key.replace(/\D/gmu, '')
          if (onlyDigits.length) {
            const digitsInt = parseInt(onlyDigits)
            if (digitsInt > maxI) {
              maxI = digitsInt
            }
          }
        }

        if (maxI) {
          for (let j = 1; j <= maxI; j++) {
            this.tags[i][`output${j}`] = ''
            this.tags[i][`check${j}`] = false
          }
        }
      }

      this.requestClients()
    },
    routeClick(name) {
      this.$emit('titleName', name)
    }
  },
  emits: ['titleName'],
  created() {
    this.$emit('titleName', 'Клиенты')
    const queryKeys = Object.keys(this.$route.query)
    const filter_request = []

    if (this.$route.query.filter_autocomplete) {
      const filter_autocomplete = JSON.parse(this.$route.query.filter_autocomplete)
      filter_autocomplete.forEach((item) => {
        this.filtering(item.value, item.name, item.direct, false)
      })
    }


    if (queryKeys) {
      queryKeys.forEach((key) => {
        if (key === 'filter_autocomplete') return
        filter_request.push(`${key}=${this.$route.query[key]}`)
      })
    }
    this.requestClients(filter_request)
    this.requestRowCount()

    fetch(window.host + '/users/activities', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.statusesData = data.data.statuses
      const statusIndex = this.tags.findIndex(el => el.code === 'clientStatus')
      if (statusIndex) {
        this.statusesData.map((statusData, i) => {
          this.tags[statusIndex][`text${i + 1}`] = statusData.name
          this.tags[statusIndex][`value${i + 1}`] = statusData.id
          this.tags[statusIndex][`filterText${i + 1}`] = `Статус "${statusData.name}"`
          return statusData;
        })

        this.tags[statusIndex].number = this.statusesData.length
      }
    }).catch(err => {
      this.$store.commit('techStore/checkError', {err})
    })


  }
}
</script>

<style lang="scss" src="./clients.scss"/>
