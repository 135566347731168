<template>
  <svg viewBox="0 0 20 20" class="arrow-icon">
    <path d="M14.9863 10.811L9.96477 15.8327M9.96477 15.8327L5.01455 10.8825M9.96477 15.8327L9.96477 4.16602" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.9863 10.811L9.96477 15.8327M9.96477 15.8327L5.01455 10.8825M9.96477 15.8327L9.96477 4.16602" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'xArrow'
}
</script>

<style lang="scss" src="./xArrow.scss" scoped />
