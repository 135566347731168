<template>
  <div class="chat-list-item"
       @click="$emit('select', chat.id)"
       :class="{ 'chat-list-item__active': chat.selected || chat.messages }"
  >
    <x-avatar class="chat-list-avatar" alt="" :src="getAvatarSrc(chat.avatar)" />
    <div class="chat-list-info">
      <div class="chat-list-info__top">
        <div class="chat-list-name">
          {{ chat.name }}
        </div>
        <div class="chat-list-date">
          {{ chat.time }}
        </div>
      </div>
      <div class="chat-list-info__bottom">
        <div class="chat-list-text">{{ chat.last_message ?? 'Начните диалог' }}</div>
        <notifyNumber :number="chat.messages" />
      </div>
    </div>
  </div>
</template>

<script>
import NotifyNumber from '@/components/notifyNumber';
import XAvatar from '@/components/xAvatar';

export default {
  name: 'chatListItem',
  components: {XAvatar, NotifyNumber},
  props: {
    chat: {
      type: Object,
      required: true
    }
  },
  methods: {
    // Получение аватаров в зависимости от пола и id
    getAvatarSrc(avatar) {
      if (!this.chat.avatar?.gender || this.chat.avatar?.id == null) {
        return require('@/assets/img/avatars/male/male-1.svg');
      }
      return require(`@/assets/img/avatars/${avatar.gender}/${avatar.gender}-${avatar.id}.svg`);
    }
  }
}
</script>

<style lang="scss" src="./chatListItem.scss" scoped></style>
