<template>
  <router-view/>
</template>
<script>
export default {
  watch: {
    $route() {
      if(this.$store.state.animateStore.isShowNotification && this.$store.state.animateStore.status === 'error'){
        this.$store.commit('animateStore/closeNotification')
      }
    }
  }
}
</script>
<style src="./global.scss" lang="scss"/>
