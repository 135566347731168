const url = 'https://bonusplan.online/api/v1/'
export const fetchData = async (endpoint, options = {}) => {

  try {
    const response = await fetch(url + endpoint, {
      ...options,
      headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token') || ''}`}
    });
    return await response.json();
  } catch (e) {
    console.log(e)
    return {}
  }
};
