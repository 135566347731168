<template>
  <div class="layout-1">
    <div class="operations-total analytics-block">
      <div class="columns-4">
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Всего покупок <br> (сумма / шт.)</div>
          <router-link class="link columns-4-link" to="/admin/operations">1 316 513 / 1 201</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Оплачено <br> (бонусы)</div>
          <router-link class="link columns-4-link" to="/admin/operations">26 870 (2,0%)</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Оплачено <br> (деньги)</div>
          <router-link class="link columns-4-link" to="/admin/operations">1 289 643</router-link>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Средний чек</div>
          <router-link class="link columns-4-link" to="/admin/operations">782,5</router-link>
        </div>
      </div>
    </div>
    <div class="operations-periods analytics-block">
      <h2 class="title operations-title">
        Анализ продаж по периодам
      </h2>
      <div class="analytics-graph" style="width: 100%">
        <VueHighcharts
          type="chart"
          :options="periodOptions"
          :animateOnUpdate="true"
        />
      </div>
    </div>
    <div class="operations-branches analytics-block">
      <h2 class="title operations-title">
        Анали продаж по филиалам
      </h2>
      <div class="analytics-graph">
        <VueHighcharts
          type="chart"
          :options="filiationOptions"
          :animateOnUpdate="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import VueHighcharts from 'vue3-highcharts'

export default {
  name: 'analyticsOperations',
  components: {
    VueHighcharts
  },
  setup () {
    const router = useRouter()
    const periodData = ref([1101250, 1678800, 952300, 1450420, 1315478, 1001250, 1352800, 1371310, 1330800, 1392200, 1368800, 1368800])
    const filiationData = ref([2709958, 2456931, 2230421, 3001203, 2156233, 2556253])

    const columnRoutesData = ref()

    const periodOptions = computed(() => ({
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      legend: {
        align: 'right'
      },
      subtitle: {
        text: null
      },
      xAxis: {
        categories: ['ноя ‘20', 'дек ‘20', 'янв ‘21', 'фев ‘21', 'мар ’21', 'апр ‘21', 'май ‘21', 'июн ‘21', 'июл ‘21', 'авг ‘21', 'сен ‘21', 'окт ‘21']
      },
      colors: ['#552490'],
      yAxis: [{
        title: null
      },
      {
        min: 0,
        title: null,
        offset: 30,
        tickLength: 30,
        tickWidth: 1,
        tickColor: 'transparent',
        labels: {
          format: '{value}%',

          style: {
            color: '#FF7F27'
          },
          x: -5
        },
        opposite: true
      }],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }
        },
        series: {
          cursor: 'pointer',
          events: {
            click: function (event) {
              const seriesName = event.point.series.name
              if (seriesName === 'Выручка') {
                columnRoutesData.value = event.point.options.y
              }
            }
          },
          states: {
            // inactive: {
            //   opacity: 1
            // }
          }
        }
      },
      series: [
        {
          name: 'Выручка',
          data: periodData.value,
          type: 'column'
        },
        {
          name: 'Процент бонусами',
          type: 'spline',
          yAxis: 1,
          color: '#FF7F27',
          data: [3, 3.5, 2.7, 2.9, 3.1, 3, 3.5, 2.7, 2.9, 3.1, 4, 3.1],
          tooltip: {
            valueSuffix: ' mm'
          }
        }
      ]
    }))
    const filiationOptions = computed(() => ({
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      legend: {
        align: 'right'
      },
      subtitle: {
        text: null
      },
      xAxis: {
        categories: ['Проспект Мира', 'Академическая', 'Водный стадион', 'Саларьево', 'Китай-Город', 'Митино']
      },
      colors: ['#552490'],
      yAxis: [{
        title: null
      },
      {
        min: 0,
        title: null,
        offset: 30,
        tickLength: 30,
        tickWidth: 1,
        tickColor: 'transparent',
        labels: {
          format: '{value}%',

          style: {
            color: '#FF7F27'
          },
          x: -5
        },
        opposite: true
      }],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }
        },
        series: {
          cursor: 'pointer',
          events: {
            click: function (event) {
              const seriesName = event.point.series.name
              if (seriesName === 'Выручка') {
                columnRoutesData.value = event.point.options.y
              }
            }
          }
        }
      },
      series: [
        {
          name: 'Выручка',
          data: filiationData.value,
          type: 'column'
        },
        {
          name: 'Процент бонусами',
          type: 'spline',
          yAxis: 1,
          color: '#FF7F27',
          data: [3, 3.5, 2.7, 2.9, 3.1, 3],
          tooltip: {
            valueSuffix: ' mm'
          }
        }
      ]
    }))
    watch(columnRoutesData, () => {
      router.push('/admin/clients')
    })

    return {
      periodOptions,
      filiationOptions
    }
  }
}
</script>

<style lang="scss" src="./analyticsOperations.scss" scoped/>
<style lang="scss" src="../analytics.scss" scoped/>
