<template>
  <label class="label">
<!--    <input -->
<!--      class="radio" -->
<!--      type="radio" -->
<!--      :name="name" -->
<!--      :value="value"-->
<!--      @change="$emit('change', $event.target.value)"-->
<!--    />-->
    <input
      class="radio"
      type="radio"
      :name="name"
      :checked="value"
      @change="$emit('change', $event.target.value)"
    />
    <span class="checkmark"></span>
    <span v-if="text" class="label-text">
      {{ text }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'radioInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    text: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    }
  }
  // computed: {
  //   isChecked () {
  //     return this.modelValue === this.value
  //   }
  // }
}
</script>

<style lang="scss" src="./radioInput.scss" scoped/>
