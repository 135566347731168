<template>
  <div class="preloader-fullScreen">
    <spinner />
  </div>
</template>

<script>
import Spinner from '@/components/preloader/spinner';

export default {
  name: 'preloaderFullScreen',
  components: {
    Spinner
  }
}
</script>

<style lang="scss" src="./preloaderFullScreen.scss" scoped  />
